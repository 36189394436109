<template>
  <div class="mb-7">
    <slot
      :current-step="currentStep"
      name="sticky-bar"
      :title="selectedTitle"
      :total-steps="funnel.length"
    />

    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
    <aside
      v-for="(step, index) in funnel"
      :key="step.id"
      class="py-72 md:py-36"
      :class="{ 'pt-0 md:pt-24': index === 0 }"
      @focus="() => handleFocusedStep(index)"
      @mouseenter="() => handleFocusedStep(index)"
      @mousemove="() => handleFocusedStep(index)"
    >
      <StepSwap
        v-if="
          step.id === 'tradein' &&
          experiments['experiment.ppBuybackDropdown'] === 'withDropdown'
        "
        ref="steps"
        :is-focused="isFocusedStep(index)"
        :model="product.model"
        :step="step"
        :swap-listings="swapListings"
      />

      <Step
        v-else
        ref="steps"
        :is-focused="isFocusedStep(index)"
        :product="product"
        :step="step"
        :swap-listings="swapListings"
      >
        <template #description-grade>
          <ConditionDescription :has-premium="hasPremium" />
        </template>

        <template #guidance-grade>
          <ConditionGuidance
            :brand="product.brand"
            :tracking-model="product.model"
          />
        </template>

        <template #guidance-color>
          <Gallery
            :allow-media-viewer="false"
            :allow-partners-images="false"
            class="mb-16"
            :images="images"
            :tracking-product-model="product.model"
            tracking-zone="pp_step_color_carousel"
          />
        </template>

        <template #step-end-mobile_plan>
          <BouyguesIncompatibleSIM
            :step="step"
            :tracking-model="product.model"
          />
        </template>

        <template #step-end-sim>
          <VisibleByVerizonSIMAdvertisement
            v-if="
              hasVisibleByVerizon(
                product.includedServiceOffers.partnerPromoCodes,
              )
            "
          />
        </template>
      </Step>
    </aside>

    <VisibleByVerizonOfferStep
      v-if="
        hasVisibleByVerizon(product.includedServiceOffers.partnerPromoCodes)
      "
      class="pb-48 pt-56 md:py-0"
      :model="product.model"
    />

    <Proposal
      ref="proposal"
      :grade="grade"
      :is-focused="isProposalFocused"
      :mobile-plan="selectedMobilePlan"
      :price="price"
      :price-without-subsidies
      :product="product"
      :selected-color-code="selectedColorCode"
      :selected-options="selectedOptions"
      :swap-offer="swapOffer"
      :swap-status="swapStatus"
      :tracking="productTracking"
      @focus="() => handleFocusedProposal()"
      @mouseenter="() => handleFocusedProposal()"
    >
      <template #details>
        <slot name="details" />
      </template>
    </Proposal>
  </div>

  <ClientOnly>
    <PremiumBatteryModal v-if="showBatteryModal" :brand="product.brand" />
    <NewBatteryModal v-if="showBatteryModal" :device-name="product.rawTitle" />
    <SimModal v-if="showSimModal" />
    <BouyguesOffersDrawer
      v-if="showBouyguesModal"
      :eligible-offers-ids="eligibleOffersIds"
    />
  </ClientOnly>
</template>

<script lang="ts" setup>
import { useRoute, useState } from '#imports'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

import { type InternalLink, type Price } from '@backmarket/http-api'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import {
  type GetPickersResponse,
  hasItemExtraData,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { urlHashToObject } from '@backmarket/utils/url/urlHashToObject'
import { useIntersectionObserver } from '@vueuse/core'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'
import StepSwap from '~/scopes/buyback/swap/components/StepSwap/StepSwap.vue'
import { SWAP_NONE } from '~/scopes/buyback/swap/components/SwapBlock/constants'
import { getFilteredItemsFromVariation } from '~/scopes/product/utils/getFilteredItemsFromVariation'

import { useProductTracking } from '../../composables/useProductTracking'
import { hasVisibleByVerizon } from '../../utils/hasVisibleByVerizon'
import Gallery from '../Gallery/Gallery.vue'
import NewBatteryModal from '../Pickers/components/NewBattery/components/Modal/Modal.vue'

import BouyguesIncompatibleSIM from './components/BouyguesIncompatibleSIM/BouyguesIncompatibleSIM.vue'
import BouyguesOffersDrawer from './components/BouyguesOffersDrawer/BouyguesOffersDrawer.vue'
import ConditionDescription from './components/ConditionDescription/ConditionDescription.vue'
import ConditionGuidance from './components/ConditionGuidance/ConditionGuidance.vue'
import PremiumBatteryModal from './components/PremiumBatteryModal/PremiumBatteryModal.vue'
import Proposal from './components/Proposal/Proposal.vue'
import SimModal from './components/SimModal/SimModal.vue'
import Step from './components/Step/Step.vue'
import VisibleByVerizonOfferStep from './components/VisibleByVerizonOfferStep/VisibleByVerizonOfferStep.vue'
import VisibleByVerizonSIMAdvertisement from './components/VisibleByVerizonSIMAdvertisement/VisibleByVerizonSIMAdvertisement.vue'
import { createCustomizationFunnelData } from './utils/createCustomizationFunnelData'

const props = withDefaults(
  defineProps<{
    grade: GetBestOffersResponse[number]
    pickers: GetPickersResponse['pickers']
    price: Price
    priceWithoutSubsidies?: Price
    product: GetProductResponse
    productTracking: ReturnType<typeof useProductTracking>
    selectedMobilePlan?: MobilePlanOffer
    swapListings?: Array<number>
    swapOffer?: Estimation
    swapStatus?: string
  }>(),
  {
    selectedMobilePlan: undefined,
    swapOffer: undefined,
    swapStatus: SWAP_NONE,
    swapListings: undefined,
    priceWithoutSubsidies: undefined,
  },
)

const route = useRoute()
const i18n = useI18n()
const experiments = useExperiments()
const proposal = ref<HTMLDivElement | null>(null)
const proposalObserver = ref<ReturnType<typeof useIntersectionObserver>>()
const lastYPosition = ref(0)
const currentStep = useState('current-step', () => 0)
const steps = ref<HTMLDivElement[] | null>([])
const stepsObservers = ref<ReturnType<typeof useIntersectionObserver>[]>([])
const { countryCode } = useMarketplace().market

const funnel = computed(() => {
  return createCustomizationFunnelData(
    i18n,
    props.pickers,
    props.product,
    {
      ...route,
      query: route.query,
      hash: urlHashToObject(route.hash),
      type: 'internal',
    } as InternalLink,
    props.swapStatus,
    props.swapOffer,
    experiments['experiment.ppBouyguesPosition'],
    experiments['experiment.ppBouyguesOffersFilter'],
    experiments['experiment.ppHideBatteryStep'],
    countryCode,
    experiments['experiment.ppDiscountedSwap'],
  )
})

const selectedOptions = computed(() => {
  return funnel.value
    ?.reduce<Array<string>>((acc, { options, type }) => {
      if (type !== 'service') {
        const label =
          options?.find(({ selected }) => !!selected)?.label ?? undefined
        if (label) {
          acc.push(typeof label === 'object' ? i18n(label) : label)
        }
      }

      return acc
    }, [])
    .filter(Boolean)
})

const selectedTitle = computed(() => {
  return selectedOptions.value.join(' - ')
})

const selectedColorCode = computed(() => {
  return (
    funnel.value
      ?.find(({ id }) => id === 'color')
      ?.options?.find(({ selected }) => !!selected)?.color ?? undefined
  )
})

const eligibleOffersIds = computed(() => {
  const mobilePlanItems = props.pickers?.find(
    ({ id }) => id === 'mobile_plan',
  )?.items

  if (!mobilePlanItems) return []

  // We run an A/B/C test which is filtering the items (see https://backmarket.atlassian.net/wiki/spaces/2C/pages/3960307861/2024-07-31+-+Trade-in+A+B+test+and+Bouygues+new+offers#%3ABouygues%3A-Bouygues-new-offer)
  return getFilteredItemsFromVariation(
    mobilePlanItems,
    experiments['experiment.ppBouyguesOffersFilter'],
  )
    .filter(hasItemExtraData)
    .map(({ extraData }) => extraData.id)
})

const showBatteryModal = computed(() => {
  return props.pickers?.some(({ id }) => id === 'battery')
})
const showSimModal = computed(() => {
  return props.pickers?.some(({ id }) => id === 'dual_sim')
})
const showBouyguesModal = computed(() => {
  return props.pickers?.some(({ id }) => id === 'mobile_plan')
})
const hasPremium = computed(() => {
  return !!props.pickers
    ?.find(({ id }) => id === 'grades')
    // grade.value is not defined when out of stock, so we fallback on trackingValue
    ?.items.find(({ trackingValue }) => trackingValue === '9')
})

const images = computed(() => {
  return props.product.images.slice(0, 3)
})

function isStepObserverInitialized(index: number) {
  return (
    steps.value &&
    steps.value.length > 0 &&
    stepsObservers &&
    stepsObservers.value.length > 0 &&
    stepsObservers.value[index].isActive
  )
}

function handleFocusedStep(index: number) {
  if (isStepObserverInitialized(index)) {
    currentStep.value = index
  }
}

const isProposalObserverInitialized = computed(() => {
  return proposal.value && proposalObserver.value?.isActive
})

function handleFocusedProposal() {
  if (isProposalObserverInitialized.value && funnel.value) {
    currentStep.value = funnel.value.length
  }
}

const isProposalFocused = computed(() => {
  return isProposalObserverInitialized.value && funnel.value
    ? currentStep.value === funnel.value.length
    : true
})

function isFocusedStep(index: number) {
  return isStepObserverInitialized(index) ? currentStep.value === index : true
}

function addIntersectionObservers() {
  funnel.value.forEach((_step, index) => {
    // When more than 3/4 of a step is displaying, it became the focus state.
    // We remove the focus the previous step when it starts to disappear.

    // TO Fix
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Unreachable code error
    stepsObservers.value[index] = useIntersectionObserver(
      steps.value?.[index],
      ([{ isIntersecting, boundingClientRect, intersectionRatio }]) => {
        if (
          isIntersecting &&
          parseFloat((Math.round(intersectionRatio * 4) / 4).toFixed(2)) >= 0.5
        ) {
          // On scroll down, we give the priority to the next step coming from the bottom.
          // On scroll up, we give the priority to the previous step coming from the top.
          if (
            (lastYPosition.value > window.scrollY &&
              boundingClientRect.top < 0) ||
            (lastYPosition.value <= window.scrollY &&
              boundingClientRect.top > 0)
          ) {
            handleFocusedStep(index)
          }
        }
        lastYPosition.value = window.scrollY
      },
      {
        threshold: [0, 0.25, 0.5, 0.75, 1],
      },
    )
  })

  proposalObserver.value = useIntersectionObserver(
    proposal.value,
    ([{ boundingClientRect, isIntersecting, intersectionRatio }]) => {
      // When more than 3/4 of a step is displaying, it became the focus state.
      // We remove the focus the previous step when it starts to disappear.
      if (
        isIntersecting &&
        parseFloat((Math.round(intersectionRatio * 4) / 4).toFixed(2)) >= 0.25
      ) {
        if (
          (lastYPosition.value > window.scrollY &&
            boundingClientRect.top < 0) ||
          (lastYPosition.value <= window.scrollY && boundingClientRect.top > 0)
        ) {
          handleFocusedProposal()
        }
      }
      lastYPosition.value = window.scrollY
    },
    {
      threshold: [0, 0.25, 0.5, 0.75, 1],
    },
  )
}

function clearIntersectionObservers() {
  stepsObservers.value.forEach((observer) => {
    observer.stop()
  })

  stepsObservers.value = []

  proposalObserver.value?.stop()
  proposalObserver.value = undefined
}

onMounted(() => {
  addIntersectionObservers()
})

onUnmounted(() => {
  clearIntersectionObservers()
})

watch([funnel], () => {
  clearIntersectionObservers()
  addIntersectionObservers()
})
</script>

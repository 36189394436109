import type { Router } from 'vue-router'

import { type Locale, MarketCountryCode } from '@backmarket/http-api'
import {
  type GetPickersResponse,
  type GetPickersResponseV2,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { getAllMarkets } from '@backmarket/nuxt-module-marketplace/getAllMarkets'
import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'
import { stringify } from '@backmarket/utils/query-string/stringify'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'

export const transformPickersResponse = (
  response: GetPickersResponseV2,
  countryCode: MarketCountryCode,
  locale: Locale,
  router: Router,
): GetPickersResponse => ({
  pickers: response.pickerGroups.map((group) => ({
    ...group,
    items: group.items.map((item) => ({
      ...item,
      grade: item.parameters.grade,
      specialOfferType: item.parameters.specialOfferType
        ? {
            type: item.parameters.specialOfferType.value,
            name: item.parameters.specialOfferType.name,
          }
        : undefined,
      extraData: item.extraData?.mobilePlanOffer,
      color: item.color || undefined,
      link: item.available
        ? {
            type: 'internal',
            name: 'product',
            pattern: '/:locale/p/:slugV2/:uuid',
            params: {
              slugV2: item.slug,
              uuid: item.productId,
              locale: toBcp47Locale(locale),
            },
            hash: removeEmptyValuesInObject({
              l: String(item.parameters.grade?.value),
              offerType: String(item.parameters.specialOfferType?.value),
              mobilePlanOfferId: item.parameters.mobilePlanOfferId,
            }) as Record<string, string>,
            query: {},
            href: `https://www${getAllMarkets()[countryCode]?.domain}${
              router.resolve({
                name: 'product',
                params: {
                  slugV2: item.slug,
                  uuid: item.productId,
                  locale: toBcp47Locale(locale),
                },
                hash: stringify({
                  l: String(item.parameters.grade?.value),
                  offerType: String(item.parameters.specialOfferType?.value),
                  mobilePlanOfferId: item.parameters.mobilePlanOfferId,
                }),
                query: {},
              }).href
            }`,
          }
        : undefined,
    })),
  })),
})

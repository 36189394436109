<template>
  <div class="flex flex-col place-items-center justify-center">
    <div v-if="variant !== 'compact'" class="body-1-bold mb-16">
      {{ i18n(translations.totalPrice, { totalPrice }) }}
    </div>

    <RevButton
      :disabled="disabled"
      :full-width="variant === 'compact' ? 'always' : 'never'"
      :loading="loading"
      variant="primary"
      @click="addToCart"
    >
      <template v-if="variant === 'compact'">
        {{ i18n(translations.ctaWithPrice, { totalPrice }) }}
      </template>
      <template v-else>
        {{ i18n(translations.cta) }}
      </template>
    </RevButton>

    <div class="mt-16">
      <div
        v-if="errorAddingToCart && variant === 'compact'"
        class="text-action-danger-hi body-2-bold text-center"
      >
        {{ i18n(translations.genericError) }}
      </div>
      <div
        v-else
        class="text-static-info-hi flex items-center md:max-w-[206px]"
      >
        <IconInfo
          v-if="variant === 'compact'"
          class="mr-8 h-20 w-20 shrink-0"
        />

        <span class="caption-bold md:text-center">
          {{ i18n(translations.shippingInformations) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import type { PartnerPromoCode } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { openModal } from '@ds/components/ModalBase'
import { IconInfo } from '@ds/icons/IconInfo'

import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'

import { useAddToCart } from '../../composables/useAddToCart'
import { logger } from '../../helpers/logger.helper'

import translations from './AddToCartBundleButton.translations'

const props = withDefaults(
  defineProps<{
    totalPrice: string
    variant?: 'default' | 'compact'
    mainProduct: Product
    recommendedProduct: Product
  }>(),
  {
    variant: 'default',
  },
)

const disabled = ref(true)
onMounted(() => {
  disabled.value = false
})

const i18n = useI18n()

const {
  addAllToCart,
  addingToCart,
  errorAddingToCart,
  addMobilePlanToCart,
  addPartnerPromoCodeToCart,
} = useAddToCart()
const loading = computed(() => addingToCart.value === 'pending')
const emit = defineEmits<{
  'add-to-cart': [Array<string>]
}>()

const status = computed(() => (errorAddingToCart.value ? 'error' : 'success'))
const route = useRoute()

async function addToCart() {
  await addAllToCart([
    props.mainProduct.listingId,
    props.recommendedProduct.listingId,
  ])
  if (status.value === 'success') {
    emit('add-to-cart', [
      props.mainProduct.listingId,
      props.recommendedProduct.listingId,
    ])
    openModal('ADD_TO_CART')

    if (
      props.mainProduct.mobilePlanOfferId &&
      props.mainProduct.listingPublicId
    ) {
      await addMobilePlanToCart(
        props.mainProduct.listingPublicId,
        props.mainProduct.mobilePlanOfferId,
      )
    }

    const visibleByVerizonOffer = props.mainProduct.partnerPromoCodes?.find(
      (partnerPromoCode: PartnerPromoCode) =>
        partnerPromoCode.partnerName === 'VISIBLE',
    )

    if (visibleByVerizonOffer && props.mainProduct.listingPublicId) {
      await addPartnerPromoCodeToCart(
        props.mainProduct.listingPublicId,
        visibleByVerizonOffer,
      )
    }
  }
  if (status.value === 'error' && errorAddingToCart.value) {
    logger.info(new Error(errorAddingToCart.value.message), {
      component: { name: 'RecommendationBundle', props },
      routeName: route.fullPath,
    })
  }
}
</script>

<template>
  <li class="mb-12">
    <LargePicker
      :addon="option.addon"
      :color="option.color"
      :data-qa="option.dataQaAttribute"
      :disabled="shouldDisplayDisabledState"
      :index="index"
      :is-good-deal="option.goodDeal"
      :is-small
      :label="label"
      :loading="loading"
      :prefix="option.style?.prefix"
      :price="description"
      :selected="option.selected"
      :sub-label="subLabel"
      :tag="tag"
      @click="onClick"
    />
  </li>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from '#imports'
import { computed, ref, watch } from 'vue'

import { type InternalLink, type LinkInternal } from '@backmarket/http-api'
import { deleteSwap } from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'
import { urlHashToObject } from '@backmarket/utils/url/urlHashToObject'
import { openModal } from '@ds/components/ModalBase'
import { type Variants as TagVariants } from '@ds/components/Tag'

import { MODAL_NAMES } from '~/scopes/buyback/constants'
import { useSwapStore } from '~/scopes/buyback/swap/stores/swap'

import { useUrlParams } from '../../../../composables/useUrlParams'
import { hashToQueryLink } from '../../../../utils/hashToQueryLink'
import { type Step } from '../../utils/types'
import LargePicker from '../LargePicker/LargePicker.vue'

import translations from './StepOption.translations'

const route = useRoute()
const router = useRouter()
const { mobilePlan } = useUrlParams()
const { trackClick } = useTracking()
const i18n = useI18n()
const { fetchSwapEstimations, resetEstimations, declineEstimation } =
  useSwapStore()
const loading = ref(false)

const props = withDefaults(
  defineProps<{
    step: Step
    option: Step['options'][number]
    index: number
    isGroupLoading?: boolean
    isSmall?: boolean
    swapListings?: Array<number>
  }>(),
  {
    isGroupLoading: false,
    isSmall: false,
    swapListings: undefined,
  },
)

const emit = defineEmits<{
  click: [Step['options'][number], Step]
  changed: [Step['options'][number], Step]
}>()

// When we click on a picker, the `loading` state is added manually. Behind the scene, we're triggering
// a /pickers endpoint call, so let's remove the loading state once the picker status change to selected
// (as it's backend driven)
watch(
  () => props.option.selected,
  () => {
    if (loading.value && props.option.selected) {
      emit('changed', props.option, props.step)
      loading.value = false
    }
  },
)

const description = computed(() => {
  if (props.step.id === 'battery' && !props.option.acquirable) {
    return ''
  }

  if (props.step.id === 'battery' && !props.option.available) {
    return i18n(translations.comingSoon)
  }

  if (!props.option.available && props.step.id !== 'mobile_plan') {
    return i18n(translations.soldOut)
  }

  if (!props.option.price) {
    return ''
  }

  // For trade-in, the price is an already formatted string :'(
  if (typeof props.option.price === 'string') {
    return props.option.price
  }

  return i18n.price(props.option.price)
})

const label = computed(() => {
  return typeof props.option.label === 'string'
    ? props.option.label
    : i18n(props.option.label)
})

const subLabel = computed(() => {
  return typeof props.option.subLabel === 'object'
    ? i18n(props.option.subLabel)
    : props.option.subLabel
})

const tag = computed(() => {
  if (props.option.tag) {
    return {
      label:
        typeof props.option.tag === 'object'
          ? i18n(props.option.tag)
          : props.option.tag,
      variant: 'primary' as TagVariants,
    }
  }

  return ''
})

const shouldDisplayDisabledState = computed(() => {
  return !props.option.available || (!loading.value && props.isGroupLoading)
})

const to = computed((): InternalLink | undefined => {
  if (!props.option.link || shouldDisplayDisabledState.value) {
    return undefined
  }

  return props.option.link
})

async function onClick() {
  if (!props.option.available || loading.value || props.isGroupLoading) {
    return false
  }

  if (props.step.id === 'battery') {
    // We have an exception on new battery for now, to be compatible with the legacy event we have on the
    // toggle in the previous pickers.
    trackClick({
      zone: props.step.trackingId ?? '',
      name: props.step.trackingId ?? 'unknown_picker',
      value: props.option.trackingValue ?? '',
    })
  } else {
    // We're a bit defensive on those trackingId / trackingValue, as the API specs says that
    // both fields are optional. Let's have default values to make sure we're able to detect this
    // in amplitude no matter what
    trackClick({
      zone: props.step.trackingId ?? 'unknown_picker',
      name: props.option.trackingValue ?? label.value,
    })
  }

  // // Custom work for the trade in step as we don't refresh anything from the pickers endpoint
  if (props.step.id === 'tradein') {
    // Let's open the modal by clicking Yes
    if (props.option.value === 'on') {
      openModal(MODAL_NAMES.SWAP_MIX_AND_MATCH)
    }
    // Remove the offer from the cart if the users selects no after accepting a swap offer
    if (props.option.value === 'off' && !props.option.selected) {
      await $httpFetch(deleteSwap)
      declineEstimation()
      resetEstimations()
      if (props.swapListings) {
        await fetchSwapEstimations(props.swapListings, mobilePlan.value)
      }
    }

    return false
  }

  emit('click', props.option, props.step)

  // If the field is already selected, we won't change the URL nor refresh the network calls, so
  // let's directly trigger the changed event and skip the loading state
  if (props.option.selected) {
    emit('changed', props.option, props.step)
  } else {
    loading.value = true
  }

  if (to.value) {
    const query = removeEmptyValuesInObject({
      ...to.value.query,
      'experiment.ppImmersiveStories':
        route.query?.['experiment.ppImmersiveStories'],
    }) as Record<string, string>

    const link = hashToQueryLink({
      ...to.value,
      query,
      hash: {
        ...urlHashToObject(route.hash),
        ...to.value.hash,
      },
    }) as unknown as LinkInternal

    router.push(toNuxtLink(link))
  }

  return true
}
</script>

import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'

export function hasVisibleByVerizon(
  partnerPromoCodes: GetProductResponse['includedServiceOffers']['partnerPromoCodes'],
) {
  return (
    partnerPromoCodes?.some(
      (partnerPromoCode) => partnerPromoCode.partnerName === 'VISIBLE',
    ) ?? false
  )
}

export default {
  titleStepTradeIn: {
    id: 'product_page_customization_step_trade_in_title',
    defaultMessage: 'Get this for {emphasis, html}',
  },
  titleEmphasisStepTradeIn: {
    id: 'product_page_customization_step_trade_in_emphasis',
    defaultMessage: 'even less.',
  },
  tradeInOptionNo: {
    id: 'product_page_customization_step_trade_in_option_no',
    defaultMessage: 'No',
  },
  tradeInOptionYes: {
    id: 'product_page_customization_step_trade_in_option_yes',
    defaultMessage: 'Yes, trade in a device',
  },
  tradeInOptionYesDescription: {
    id: 'product_page_customization_step_trade_in_option_yes_description',
    defaultMessage:
      'We’ll buy back your old tech for cash, which could make today’s total more like {price} after your trade-in',
  },
  tradeInTag: {
    id: 'product_page_customization_step_trade_in_tag',
    defaultMessage: 'Get cash back',
  },
  tradeInTagAccepted: {
    id: 'product_page_customization_step_trade_in_tag_accepted',
    defaultMessage: 'Your trade-in offer',
  },
  tradeInGuidance: {
    id: 'product_page_customization_step_trade_in_guidance',
    defaultMessage: 'See how Trade-in works',
  },
}

import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

export default {
  title: {
    id: 'product_page_customization_title',
    defaultMessage: 'Let’s find you one in our marketplace.',
  },
  titleStepGrade: {
    id: 'product_page_customization_step_grade_title',
    defaultMessage: 'What {emphasis, html} are you looking for?',
  },
  titleStepBattery: {
    id: 'product_page_customization_step_battery_title',
    defaultMessage: 'What {emphasis, html} do you need?',
  },
  titleStepBatteryDisabled: {
    id: 'product_page_customization_step_battery_title_disabled',
    defaultMessage: 'Guaranteed {emphasis, html} regardless!',
  },
  titleStepBatteryPremium: {
    id: 'product_page_customization_step_battery_title_premium',
    defaultMessage: 'Your phone comes with an {emphasis, html}',
  },
  titleStepStorage: {
    id: 'product_page_customization_step_storage_title',
    defaultMessage: 'How much {emphasis, html} do you need?',
  },
  titleStepSim: {
    id: 'product_page_customization_step_sim_title',
    defaultMessage: 'What {emphasis, html} do you need?',
  },
  titleStepColor: {
    id: 'product_page_customization_step_color_title',
    defaultMessage: 'Lastly, what {emphasis, html} do you like most?',
  },
  titleEmphasisStepGrade: {
    id: 'product_page_customization_step_grade_emphasis',
    defaultMessage: 'aesthetic',
  },
  titleEmphasisStepBattery: {
    id: 'product_page_customization_step_battery_emphasis',
    defaultMessage: 'battery',
  },
  titleEmphasisStepBatteryDisabled: {
    id: 'product_page_customization_step_battery_emphasis_disabled',
    defaultMessage: 'great battery',
  },
  titleEmphasisStepBatteryPremium: {
    id: 'product_page_customization_step_battery_emphasis_premium',
    defaultMessage: 'official {brand} battery',
  },
  titleEmphasisStepStorage: {
    id: 'product_page_customization_step_storage_emphasis',
    defaultMessage: 'storage',
  },
  titleEmphasisStepSim: {
    id: 'product_page_customization_step_sim_emphasis',
    defaultMessage: 'SIM',
  },
  titleEmphasisStepColor: {
    id: 'product_page_customization_step_color_emphasis',
    defaultMessage: 'color',
  },
  newBatteryOptionNo: {
    id: 'product_page_customization_step_battery_option_no',
    defaultMessage: 'Default battery',
  },
  newBatteryOptionNoDescription: {
    id: 'product_page_customization_step_battery_option_no_description',
    defaultMessage: 'perfect enough for daily usage',
  },
  newBatteryOptionYes: {
    id: 'product_page_customization_step_battery_option_yes',
    defaultMessage: 'New battery installed',
  },
  newBatteryOptionYesDescription: {
    id: 'product_page_customization_step_battery_option_yes_description',
    defaultMessage: 'best for heavy use',
  },
  newBatteryOptionYesPremium: {
    id: 'product_page_customization_step_battery_option_yes_premium',
    defaultMessage: 'Premium battery',
  },
  newBatteryOptionYesDescriptionPremium: {
    id: 'product_page_customization_step_battery_option_yes_description_premium',
    defaultMessage: '{brand} verified, best for heavy use',
  },
  helpCardBattery: {
    id: 'product_page_customization_step_battery_help',
    defaultMessage: 'See if your phone usage actually requires a new battery.',
  },
  helpCardBatteryPremium: {
    id: 'product_page_customization_step_battery_help_premium',
    defaultMessage:
      'Premium batteries have little to no past use. See the comparison',
  },
  helpCardSim: {
    id: 'product_sim_modal_opener_customization_funnel',
    defaultMessage:
      'A SIM allows you to activate a cellular plan from your carrier and holds information like your phone number. ',
  },
  resultTitle: {
    id: 'product_page_customization_result_title',
    defaultMessage: 'Tadaaaa!',
  },
  resultSeller: {
    id: 'product_page_customization_result_seller',
    defaultMessage: 'Proudly refurbished by {seller, html}',
  },
  resultAddToCart: {
    id: 'product_page_customization_result_add_to_cart',
    defaultMessage: 'Add to cart',
  },
  mobilePlanSubsisdy: {
    id: 'product_page_customization_mobile_plan_subsidy_tag',
    defaultMessage: '{subsidy} de remise',
  },

  sim_eSIM: {
    id: 'product_sim_modal_combo_option_esim_desc',
    defaultMessage: '1 virtual slot',
  },
  'sim_Single-SIM': {
    id: 'product_sim_modal_combo_option_single_sim_desc',
    defaultMessage: '1 physical slot',
  },
  'sim_Single-SIM + eSIM': {
    id: 'product_sim_modal_combo_option_sim_esim_desc',
    defaultMessage: '1 physical + 1 virtual slot',
  },
  'sim_Dual eSIM': {
    id: 'product_sim_modal_combo_option_dual_esim_desc',
    defaultMessage: '2 virtual slots',
  },
  'sim_Dual-SIM': {
    id: 'product_sim_modal_combo_option_dual_sim_desc',
    defaultMessage: '2 physical slots',
  },
  'sim_Dual-SIM + eSIM': {
    id: 'product_sim_modal_combo_option_dual_sim_esim_desc',
    defaultMessage: '2 physical + 1 virtual slot',
  },
  'sim_Hybrid Dual SIM': {
    id: 'product_sim_modal_combo_option_hybrid_desc',
    defaultMessage: '2 physical slots',
  },
  tradeInProposalName: {
    id: 'product_page_customization_result_trade_in_name',
    defaultMessage: 'Your trade-in: {device}',
  },
  tradeInProposalPrice: {
    id: 'product_page_customization_result_trade_in_price',
    defaultMessage: '{price} cash back',
  },
  tradeInProposalDescription: {
    id: 'product_page_customization_result_trade_in_description',
    defaultMessage:
      'You’ll get paid {price} once we receive your device and confirm it matches the assessment.',
  },
  bestValue: {
    id: 'product_page_pickers_best_value',
    defaultMessage: 'Recommended',
  },
} as Record<string, I18nDefinition>

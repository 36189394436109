export default {
  buttonLabel: {
    id: 'visible_by_verizon_offer_button_label',
    defaultMessage: 'See plan details',
  },
  termsApply: {
    id: 'visible_by_verizon_offer_terms_apply',
    defaultMessage: 'Terms apply.',
  },
  titleOnMobile: {
    id: 'visible_by_verizon_offer_title_on_mobile',
    defaultMessage: 'Get your exclusive savings with Visible+.',
  },
  titleOnDesktop: {
    id: 'visible_by_verizon_offer_title_on_desktop',
    defaultMessage: 'Get your exclusive savings with {offerName, html}',
  },
  offerName: {
    id: 'visible_by_verizon_offer_title_offer_name',
    defaultMessage: 'Visible+',
  },
  offerLabel: {
    id: 'visible_by_verizon_offer_title_offer_label',
    defaultMessage:
      'Unlimited 5G data with access to our fastest network and hotspot for {offerPriceWithoutDiscount, html} $30/month for 6 months.',
  },
  offerPriceWithoutDiscount: {
    id: 'visible_by_verizon_offer_title_offer_price_without_discount',
    defaultMessage: '$45',
  },
  logo: {
    id: 'visible_by_verizon_offer_partnered_with_back_market',
    defaultMessage: 'Visible partnered with Back Market',
  },
}

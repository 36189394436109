export default {
  conditions: {
    id: 'grades_modal_condition',
    defaultMessage: 'Conditions',
  },
  moreInfo: {
    id: 'grades_modal_more_info',
    defaultMessage: 'More info',
  },
  alternativeController: {
    id: 'alternative-controller',
    defaultMessage: 'Controller',
  },
  alternativeCurrent: {
    id: 'alternative-current',
    defaultMessage: 'Current Slide',
  },
  alternativeNext: {
    id: 'alternative-next',
    defaultMessage: 'Next',
  },
  alternativePrevious: {
    id: 'alternative-previous',
    defaultMessage: 'Previous',
  },
  excellent1_pill: {
    id: 'product_page_customization_condition_guidance_excellent_1_pill',
    defaultMessage: 'Almost no signs of use',
  },
  excellent1_title: {
    id: 'product_page_customization_condition_guidance_excellent_1_title',
    defaultMessage: 'Body',
  },
  excellent2_pill: {
    id: 'product_page_customization_condition_guidance_excellent_2_pill',
    defaultMessage: 'Flawless',
  },
  excellent2_title: {
    id: 'product_page_customization_condition_guidance_excellent_2_title',
    defaultMessage: 'Screen',
  },
  good1_pill: {
    id: 'product_page_customization_condition_guidance_good_1_pill',
    defaultMessage: 'Light signs of use',
  },
  good1_title: {
    id: 'product_page_customization_condition_guidance_good_1_title',
    defaultMessage: 'Body',
  },
  good2_pill: {
    id: 'product_page_customization_condition_guidance_good_2_pill',
    defaultMessage: 'Flawless',
  },
  good2_title: {
    id: 'product_page_customization_condition_guidance_good_2_title',
    defaultMessage: 'Screen',
  },
  fair1_pill: {
    id: 'product_page_customization_condition_guidance_fair_1_pill',
    defaultMessage: 'Visible signs of use',
  },
  fair1_title: {
    id: 'product_page_customization_condition_guidance_fair_1_title',
    defaultMessage: 'Body',
  },
  fair2_pill: {
    id: 'product_page_customization_condition_guidance_fair_2_pill',
    defaultMessage: 'May have micro-scratches',
  },
  fair2_title: {
    id: 'product_page_customization_condition_guidance_fair_2_title',
    defaultMessage: 'Screen',
  },
  premium1_pill1: {
    id: 'product_page_customization_condition_guidance_premium_1_pill_1',
    defaultMessage: 'Best quality',
  },
  premium1_pill2: {
    id: 'product_page_customization_condition_guidance_premium_1_pill_2',
    defaultMessage: 'No signs of use',
  },
  premium1_pill3: {
    id: 'product_page_customization_condition_guidance_premium_1_pill_3',
    defaultMessage: '{brand} parts',
  },
  premium1_title: {
    id: 'product_page_customization_condition_guidance_premium_1_title',
    defaultMessage: 'Body',
  },
  premium2_pill: {
    id: 'product_page_customization_condition_guidance_premium_2_pill_1',
    defaultMessage: 'Flawless',
  },
  premium2_title: {
    id: 'product_page_customization_condition_guidance_premium_2_title',
    defaultMessage: 'Screen',
  },
  disclaimer: {
    id: 'product_page_customization_condition_guidance_disclaimer',
    defaultMessage: 'Example image',
  },
  pillVerifiedParts: {
    id: 'product_page_customization_condition_guidance_verified_parts',
    defaultMessage: 'Verified parts',
  },
  pillBatteryForDailyUse: {
    id: 'product_page_customization_condition_guidance_pill_batter_daily_use',
    defaultMessage: 'Battery for daily use',
  },
  pillBatteryForHeavyUse: {
    id: 'product_page_customization_condition_guidance_pill_batter_heavy_use',
    defaultMessage: 'Battery for heavy use',
  },
}

import { MarketCountryCode } from '@backmarket/http-api'

export const COUNTRIES_WITHOUT_ORIGINAL_PRICE = [MarketCountryCode.JP]

export const COUNTRIES_WITH_TRADE_IN_PRICE_LABEL = [
  MarketCountryCode.US,
  MarketCountryCode.GB,
]

export const COUNTRIES_WITH_TRADE_IN_PRICE_LABEL_DISCOUNTED_SWAP = [
  MarketCountryCode.US,
  MarketCountryCode.GB,
  MarketCountryCode.FR,
  MarketCountryCode.DE,
  MarketCountryCode.ES,
]

import { type ComputedRef, type Ref, computed, onMounted, watch } from 'vue'

import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import { storeToRefs } from 'pinia'

import {
  SWAP_DISABLED,
  SWAP_ERROR,
  SWAP_LOADING,
  SWAP_NONE,
  SWAP_SUCCESS,
} from '~/scopes/buyback/swap/components/SwapBlock/constants'
import { EndpointStatus, useSwapStore } from '~/scopes/buyback/swap/stores/swap'
import { useUrlParams } from '~/scopes/product/composables/useUrlParams'

export function useSwapEstimations({
  isSwapEligible,
  grade,
  bestOffersResponse,
}: {
  isSwapEligible: boolean
  grade: ComputedRef
  bestOffersResponse: Ref<GetBestOffersResponse | null>
}) {
  const store = useSwapStore()
  const { estimations } = storeToRefs(store)
  const { grade: urlGrade, mobilePlan: urlMobilePlan } = useUrlParams()

  const swapOffer = computed(() => {
    return estimations.value.find(
      (estimation) => estimation.id === grade.value?.id,
    )
  })

  const isSwapInCart = computed(() => {
    return swapOffer?.value?.hasOffer || false
  })

  const noSwapHaveBeenEstimated = computed(() => {
    return swapOffer.value && store.enabled && !isSwapInCart.value
  })

  const swapStatus = computed(() => {
    if (!isSwapEligible) {
      return SWAP_DISABLED
    }

    if (store.status === EndpointStatus.PENDING) {
      return SWAP_LOADING
    }

    if (store.status === EndpointStatus.REJECTED) {
      return SWAP_ERROR
    }

    if (noSwapHaveBeenEstimated.value) {
      return SWAP_NONE
    }

    return SWAP_SUCCESS
  })

  const swapListings = computed(() => {
    return (
      (bestOffersResponse.value &&
        bestOffersResponse.value.reduce((availableListings, currentGrade) => {
          if (currentGrade?.id) {
            availableListings.push(currentGrade.id)
          }

          return availableListings
        }, [] as Array<number>)) ||
      []
    )
  })

  async function refreshSwap(listings: Array<number>) {
    if (isSwapEligible) {
      await store.fetchSwapEstimations(listings, urlMobilePlan.value)
    }
  }

  watch([urlGrade, urlMobilePlan], async () => {
    await refreshSwap(swapListings.value)
  })

  onMounted(async () => {
    await refreshSwap(swapListings.value)
  })

  return {
    swapStatus,
    swapListings,
    swapOffer,
  }
}

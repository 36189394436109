import type { Router } from 'vue-router'

import { type Locale, MarketCountryCode } from '@backmarket/http-api'
import {
  type GetProductResponse,
  type GetProductResponseV2,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { getAllMarkets } from '@backmarket/nuxt-module-marketplace/getAllMarkets'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'

const typesMap: Record<
  GetProductResponseV2['type'],
  GetProductResponse['type']
> = {
  Refurbished: 'refurbished',
  'Brand new': 'accessory',
  Digital: 'accessory',
}

export const transformProductResponse = (
  product: GetProductResponseV2,
  countryCode: MarketCountryCode,
  locale: Locale,
  router: Router,
): GetProductResponse => ({
  id: product.productId,
  legacyId: product.productId,
  model: product.model,
  priceWhenNew: product.priceWhenNew,
  isSwapEligible: product.isTradeInEligible,
  subTitleElements: product.subtitleElements,
  images: product.images,
  brand: product.brand,
  tracking: product.tracking,
  tags: [],
  insurancesOffers: {},
  eWaste: '',
  description: product.seo.description,
  showVerifiedRefurbished: product.showVerifiedRefurbished,
  link: {
    type: 'internal',
    name: 'product',
    pattern: '/:locale/p/:slugV2/:uuid',
    params: {
      slugV2: product.seo.slug,
      uuid: product.productId,
      locale: toBcp47Locale(locale),
    },
    query: {},
    hash: {},
    href: `https://www${getAllMarkets()[countryCode]?.domain}${
      router.resolve({
        name: 'product',
        params: {
          slugV2: product.seo.slug,
          uuid: product.productId,
          locale: toBcp47Locale(locale),
        },
      }).href
    }`,
  },
  links: product.seo.marketsLinks
    .filter(({ slug }) => !!slug)
    .reduce(
      (acc, cur: GetProductResponseV2['seo']['marketsLinks'][number]) => ({
        ...acc,
        [cur.countryCode]: {
          type: 'internal',
          name: 'product',
          pattern: '/:locale/p/:slugV2/:uuid',
          params: {
            slugV2: cur.slug,
            uuid: product.productId,
            locale: toBcp47Locale(
              getAllMarkets()[cur.countryCode]?.defaultLocale ?? locale,
            ),
          },
          href: `https://www${getAllMarkets()[cur.countryCode]?.domain}${
            router.resolve({
              name: 'product',
              params: {
                slugV2: cur.slug,
                uuid: product.productId,
                locale: toBcp47Locale(
                  getAllMarkets()[cur.countryCode]?.defaultLocale ?? locale,
                ),
              },
            }).href
          }`,
        },
      }),
      {},
    ),
  type: typesMap[product.type],
  title: product.titles.default,
  rawTitle: product.titles.raw,
  carrier: product.carrier,
  includedServiceOffers: product.includedServiceOffers,
  comesWith: product.comesWith,
  isProductRematched: product.isProductRematched,
  seo: product.seo,
})

<template>
  <Chip
    v-if="label"
    :class="{ 'order-first': isActive }"
    :is-active
    isInteractive
    :label
    @click="openBouyguesModal"
  >
    <template #prefix>
      <RevIllustration
        :alt="i18n(translations.bouyguesIcon)"
        :height="20"
        src="/img/bouygues/bouyguesLogoSquare.svg"
        :width="20"
      />
    </template>
  </Chip>

  <BouyguesOffersDrawer
    :eligible-offers-ids="offers.map((offer) => offer.id)"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevIllustration } from '@ds/components/Illustration'
import { openModal } from '@ds/components/ModalBase'

import Chip from '~/scopes/product/components/Chip/Chip.vue'
import BouyguesOffersDrawer from '~/scopes/product/components/CustomizationFunnel/components/BouyguesOffersDrawer/BouyguesOffersDrawer.vue'
import { useUrlParams } from '~/scopes/product/composables/useUrlParams'
import { MODAL_NAMES } from '~/scopes/product/constants'
import { getHighestSubsidy } from '~/scopes/product/utils/getHighestSubsidy'

import translations from './BouyguesChip.translations'

const i18n = useI18n()
const { trackClick } = useTracking()
const { mobilePlan } = useUrlParams()

const props = defineProps<{
  offers: GetProductResponse['includedServiceOffers']['mobilePlans']
}>()

const isActive = computed(() => {
  return !isEmpty(mobilePlan.value)
})

const subsidy = computed(() => {
  const highest = getHighestSubsidy(props.offers)
  const selected = props.offers.find(
    (offer) => offer.id === mobilePlan.value,
  )?.subsidy

  return selected || highest
})

const label = computed(() => {
  return subsidy.value
    ? i18n(
        isActive.value
          ? translations.bouyguesChipContentWhenEnabled
          : translations.bouyguesChipContent,
        {
          subsidy: i18n.price(subsidy.value),
        },
      )
    : ''
})

function openBouyguesModal() {
  trackClick({
    name: 'bouygues_chip',
    zone: 'bouygues_info_title_section',
  })

  openModal(MODAL_NAMES.BOUYGUES)
}
</script>

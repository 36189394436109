<template>
  <RevButtonBase
    :aria-disabled="disabled"
    class="focus-visible-outline-default-hi rounded-sm relative flex size-full flex-col border py-12 no-underline"
    :class="{
      'bg-static-default-low-disabled border-action-default-low-disabled':
        disabled,
      'bg-surface-brand-hi border-action-default-low-pressed hover:bg-surface-brand-hi hover:border-action-default-low-pressed':
        selected,
      'bg-static-default-low border-action-default-low hover:bg-static-default-low-hover':
        !disabled && !selected,
    }"
    :disabled="disabled"
    @click="emit('click')"
  >
    <RevSpinner
      v-if="loading"
      aria-hidden
      class="text-action-default-hi absolute left-0 top-1/2 -mt-12 w-full"
    />

    <div class="m-auto flex w-full flex-row items-center pl-8 pr-16">
      <div
        v-if="icon"
        class="shrink-0"
        :class="{ invisible: loading }"
        data-test="icon"
      >
        <component :is="icon" v-bind="iconProps" />
      </div>
      <div class="ml-16 flex grow flex-col" :class="{ invisible: loading }">
        <div
          class="flex grow flex-nowrap items-start text-left"
          :class="{ 'flex-row': !isSmall, 'flex-col': isSmall }"
        >
          <span
            class="mr-8 min-w-[100px] grow"
            :class="{
              'text-action-default-hi body-1-bold': selected,
              'text-action-default-hi-disabled body-1': disabled,
              'text-action-default-hi body-1': !disabled && !selected,
            }"
          >
            {{ label }}
          </span>
          <span
            class="body-2 shrink-0"
            :class="{
              'text-action-default-low': !disabled && !isGoodDeal,
              'text-action-default-low-disabled': disabled,
              'text-static-success-hi flex items-center gap-4':
                !disabled && isGoodDeal,
              'pt-3': !isSmall && !isGoodDeal,
              invisible: loading,
            }"
          >
            <IconArrowDownRight
              v-if="isGoodDeal"
              :alternative-text="i18n(translations.goodDeal)"
            />
            {{ price }}
          </span>
        </div>
        <div
          v-if="subLabel && (!addon || !selected)"
          class="caption pt-4 text-left"
          :class="{
            'text-action-default-low': !disabled,
            'text-action-default-low-disabled': disabled,
          }"
        >
          {{ subLabel }}
        </div>
      </div>
    </div>

    <div
      v-if="addon && selected"
      class="border-static-default-low mt-12 flex w-full grow border-t pt-12"
    >
      <component :is="addon" />
    </div>

    <RevTag
      v-if="tagLabel && tagVariant"
      class="absolute -top-8 right-16"
      :class="{ invisible: loading }"
      :label="tagLabel"
      :variant="tagVariant"
    />
  </RevButtonBase>
</template>

<script lang="ts" setup>
/**
 * This component is used in place of the RevPicker provided by the design system. We used to override some of its styles to fit our needs,
 * but several issues prevented us to continue this way:
 * - The ability to position the tag differently (every picker)
 * - The ability to add a sub label (new battery, sim)
 * This will of course need to be discussed after the migration to FSM to see if those changes belongs to the existing component (as a variant?)
 * or should live in our scope.
 */
import { type VNode, computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevIllustration } from '@ds/components/Illustration'
import { RevSpinner } from '@ds/components/Spinner'
import { RevTag, type Variants as TagVariants } from '@ds/components/Tag'
import { IconArrowDownRight } from '@ds/icons/IconArrowDownRight'
import { IconDiamond } from '@ds/icons/IconDiamond'

import ColorRound from '../../../Pickers/components/Picker/ColorRound/ColorRound.vue'
import FilledDiamond from '../FilledDiamond/FilledDiamond.vue'
import PickerRound from '../PickerRound/PickerRound.vue'
import translations from '../StepOption/StepOption.translations'

import { type Prefixes } from './LargePicker.types'

const props = withDefaults(
  defineProps<{
    addon?: VNode
    disabled?: boolean
    color?: string
    index: number
    label: string
    loading?: boolean
    price?: string
    selected?: boolean
    prefix?: Prefixes
    subLabel?: string
    tag?: string | { label: string; variant?: TagVariants }
    isGoodDeal?: boolean
    isSmall?: boolean
  }>(),
  {
    addon: undefined,
    disabled: false,
    color: '',
    isGoodDeal: false,
    isSmall: false,
    loading: false,
    selected: false,
    subLabel: '',
    price: '',
    prefix: undefined,
    tag: undefined,
  },
)

const emit = defineEmits(['click', 'click-prevent'])

const i18n = useI18n()

const tagLabel = computed(() => {
  if (!props.tag) {
    return ''
  }

  return typeof props.tag === 'string' ? props.tag : props.tag?.label
})

const tagVariant = computed(() => {
  return typeof props.tag === 'string'
    ? 'primary'
    : props.tag?.variant ?? 'primary'
})

const icon = computed(() => {
  switch (props.prefix) {
    case 'AnimatedDiamondOutlined':
    case 'AnimatedDiamondFilled':
      return RevIllustration
    case 'IconDiamond':
      return IconDiamond
    case 'FilledDiamond':
      return FilledDiamond
    case 'circle':
      return PickerRound
    case 'color':
      if (props.color) {
        return ColorRound
      }

      return undefined
    default:
      return undefined
  }
})

const iconProps = computed(() => {
  switch (props.prefix) {
    case 'AnimatedDiamondOutlined':
      return {
        alt: '',
        src: '/img/product/funnel/outline-diamond.gif',
        width: 24,
        height: 24,
      }
    case 'AnimatedDiamondFilled':
      return {
        alt: '',
        src: '/img/product/funnel/filled-diamond.gif',
        width: 24,
        height: 24,
      }
    case 'IconDiamond':
      return {
        class: 'text-action-default-hi-disabled',
      }
    case 'circle':
      return {
        index: props.index,
        selected: props.selected,
        disabled: props.disabled,
      }
    case 'color':
      return {
        color: props.color,
      }
    default:
      return {}
  }
})
</script>

<template>
  <StepLayout :is-focused="isFocused" :tracking="trackingStep">
    <template #left>
      <slot :name="`guidance-${step.id}`" />

      <RevIllustration
        v-if="step.desktopIllustration"
        alt=""
        class="block min-w-[337px] max-w-[498px] rounded-[32px]"
        :height="498"
        :src="step.desktopIllustration"
        :width="498"
      />
    </template>

    <template #right>
      <h3 class="heading-1 mb-16">
        <FormattedMessage :definition="step.title">
          <template #price>
            <span v-if="step.titlePrice" class="bg-static-info-max">
              {{ i18n.price(step.titlePrice) }}
            </span>
          </template>
          <template #emphasis>
            <em>
              {{
                typeof step.titleEmphasis === 'string'
                  ? step.titleEmphasis
                  : i18n(step.titleEmphasis)
              }}
            </em>
          </template>
        </FormattedMessage>
      </h3>

      <slot :name="`description-${step.id}`" />

      <div class="block md:hidden">
        <slot :name="`guidance-${step.id}`" />
      </div>

      <CardGuidance v-if="step.guidance" v-bind="step.guidance" />

      <ul class="list-none" :class="stepOptionsClasses(step)">
        <StepOption
          v-for="(option, index) in options"
          :key="option.trackingValue"
          :index="index"
          :is-group-loading="isGroupLoading"
          :is-small="isSmallPicker"
          :option="option"
          :step="step"
          :swap-listings="swapListings"
          @changed="onOptionChanged"
          @click="onOptionClick"
        />

        <li v-if="shouldDisplayToggleButton" class="mb-12">
          <LargePicker
            :aria-expanded="displayAllItems"
            class="m-auto h-full"
            :index="options.length"
            :is-small="isSmallPicker"
            :label="toggleLabel"
            @click="toggleDisplayAllItems"
          />
        </li>
      </ul>

      <slot :name="`step-end-${step.id}`" />
    </template>
  </StepLayout>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'

import { type Step } from '../../utils/types'
import CardGuidance from '../CardGuidance/CardGuidance.vue'
import LargePicker from '../LargePicker/LargePicker.vue'
import StepLayout from '../StepLayout/StepLayout.vue'
import StepOption from '../StepOption/StepOption.vue'

import translations from './Step.translations'

const MAX_PICKER_ITEMS = 6

const props = withDefaults(
  defineProps<{
    step: Step
    isFocused?: boolean
    product: GetProductResponse
    swapListings?: Array<number>
  }>(),
  {
    isFocused: true,
    swapListings: undefined,
  },
)

const displayAllItems = ref(false)
const isGroupLoading = ref(false)
const i18n = useI18n()

const shouldDisplayToggleButton = computed(() => {
  return props.step.options.length > MAX_PICKER_ITEMS
})

const maximumAmountOfItemsToShow = computed(() => {
  return MAX_PICKER_ITEMS - 1
})

const remainingItemsCount = computed(() => {
  return props.step.options.length - maximumAmountOfItemsToShow.value
})

const options = computed(() => {
  if (shouldDisplayToggleButton.value && !displayAllItems.value) {
    return props.step.options.slice(0, maximumAmountOfItemsToShow.value)
  }

  return props.step.options
})

const trackingTags = computed(() => {
  const hasGoodDeal = props.step.options.some((option) => option.goodDeal)
  const hasSelectedGoodDeal = props.step.options.some(
    (option) => option.goodDeal && option.selected,
  )

  const hasStaffPick =
    props.step.id === 'grade' &&
    !props.step.options.some((option) => option.goodDeal)
  const hasSelectedStaffPick =
    hasStaffPick &&
    props.step.options.some(
      (option) => option.grade?.value === 11 && option.selected,
    )

  return {
    // eslint-disable-next-line no-nested-ternary
    tagDisplayed: hasStaffPick
      ? 'staff_pick'
      : hasGoodDeal
        ? 'good_deal'
        : 'no_tag',
    tagSelected: hasSelectedStaffPick || hasSelectedGoodDeal,
  }
})

const trackingStep = computed(() => {
  return {
    trackingTags: trackingTags.value,
    trackingId: props.step.trackingId,
    trackingModel: props.product.model,
  }
})

const toggleLabel = computed(() => {
  return displayAllItems.value
    ? i18n(translations.showLess)
    : i18n(translations.showMore, {
        remaining: remainingItemsCount.value,
      })
})

const isSmallPicker = computed(() => {
  return (props.step.columns ?? 1) > 1
})

function stepOptionsClasses(step: Step) {
  if (step.columns) {
    return `grid grid-cols-${step.columns} gap-x-12`
  }

  return ''
}
function toggleDisplayAllItems() {
  displayAllItems.value = !displayAllItems.value
}
function onOptionClick() {
  isGroupLoading.value = true
}
function onOptionChanged() {
  isGroupLoading.value = false
}
</script>

<template>
  <div v-if="shouldDisplayPickers">
    <PickerGroup
      v-for="group in pickersWithLoading"
      :key="group.id"
      class="mb-32"
      :picker="group"
      @change="handleChange"
    >
      <template #extra-grades>
        <NewBattery
          :grade="currentGradeLabel"
          :offer-type="newBattery?.offerType"
          :product-category="categoryId"
          :status="newBattery?.status"
          @change="handleNewBatteryChange"
        />
      </template>
    </PickerGroup>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import { type GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'
import { SWAP_SUCCESS } from '~/scopes/buyback/swap/components/SwapBlock/SwapBlock.constants'
import { getPriceWithTradeIn } from '~/scopes/product/utils/getPriceWithTradeIn'

import NewBattery from './components/NewBattery/NewBattery.vue'
import PickerGroup from './components/PickerGroup/PickerGroup.vue'

const props = defineProps<{
  newBattery?: GetBestOffersResponse[number]['newBattery']
  pickers: GetPickersResponse['pickers']
  categoryId: GetProductResponse['tracking']['categoryId']
  swapStatus?: string
  swapOffer?: Estimation
}>()

const emit = defineEmits(['change'])
const experiments = useExperiments()
const loadingGroup = ref('')
const loadingIndex = ref<number | undefined>(undefined)

watch(props, () => {
  loadingGroup.value = ''
  loadingIndex.value = undefined
})

const shouldDisplayPickers = computed(() => {
  return props.pickers.length > 0
})

const pickersWithoutBattery = computed(() => {
  return props.pickers.filter(({ id }) => id !== 'battery')
})

const pickersWithTradeInPrice = computed(() => {
  if (
    experiments['experiment.ppDiscountedSwap'] !== 'withDiscountedSwap' ||
    props.swapStatus !== SWAP_SUCCESS ||
    !props.swapOffer?.hasOffer
  ) {
    return pickersWithoutBattery.value
  }

  return pickersWithoutBattery.value.map((picker) => ({
    ...picker,
    items: picker.items.map((item) => ({
      ...item,
      price:
        item.price && props.swapOffer?.offerPriceRaw
          ? {
              amount: getPriceWithTradeIn(
                item.price,
                props.swapOffer.offerPriceRaw,
              ),
              currency: item.price.currency,
            }
          : undefined,
    })),
  }))
})

const pickersWithLoading = computed(() => {
  return pickersWithTradeInPrice.value.map((picker) => ({
    ...picker,
    items: picker.items.map((item, index) => ({
      ...item,
      loading: loadingGroup.value === picker.id && loadingIndex.value === index,
    })),
  }))
})

const currentGradeLabel = computed(() => {
  if (props.pickers) {
    return props.pickers
      .find((picker) => picker.id === 'grades')
      ?.items.find(({ selected }) => !!selected)?.label
  }

  return ''
})

function handleChange(pickerId: string, index: number) {
  loadingGroup.value = pickerId
  loadingIndex.value = index

  emit('change', pickerId, index)
}

function handleNewBatteryChange() {
  const gradePicker = pickersWithLoading.value.find(
    (picker) => picker.id === 'grades',
  )

  const selectedGradePicker = gradePicker?.items.findIndex(
    (item) => item.selected,
  )

  loadingGroup.value = 'grades'
  loadingIndex.value = selectedGradePicker
}
</script>

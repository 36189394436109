import { useRoute, useRouter } from '#imports'
import { type Ref, watch } from 'vue'

import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import { type GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { useUrlParams } from './useUrlParams'

/**
 * When the grade in the URL points towards a disabled one, we need to fallback to the one
 * selected by default (provided by the endpoint).
 *
 * This can happen in a few occasions:
 * - direct access on the pp with an out of stock grade (user favorites or history for instance)
 * - wrong grade provided by the pickers endpoint (issue raised on their side that should be fixed, but better safe than sorry)
 * - ...?
 */
export const useGradeFallback = (
  currentGrade: Ref<GetBestOffersResponse[number] | null | undefined>,
  isPickersResponsePending: Ref<boolean>,
  pickers: Ref<GetPickersResponse | null>,
  grades: Ref<GetBestOffersResponse | null | undefined>,
) => {
  const { grade: gradeInURL } = useUrlParams()
  const router = useRouter()
  const route = useRoute()
  const logger = useLogger()

  watch(
    [currentGrade, isPickersResponsePending],
    () => {
      const selectedGrade =
        pickers.value !== null
          ? pickers.value.pickers
              .find((picker) => picker.id === 'grades')
              ?.items.find(
                (grade) =>
                  grade &&
                  grade.trackingValue &&
                  parseInt(grade.trackingValue, 10) ===
                    currentGrade.value?.backboxGrade.value,
              )
          : undefined

      if (
        !gradeInURL.value ||
        !selectedGrade ||
        !grades.value ||
        isPickersResponsePending.value
      ) {
        return
      }

      if (!selectedGrade.available) {
        const fallbackGrade = grades.value.find(
          (offer) => offer.isSelectedByDefault === true,
        )

        if (fallbackGrade) {
          logger.info('[PRODUCT] Disabled grade in the URL')

          const link = {
            ...route,
            type: 'internal',
            query: {
              ...route.query,
              l: fallbackGrade.backboxGrade.value.toString(),
            },
          }

          void router.replace(link)
        }
      }
    },
    { immediate: true },
  )
}

<template>
  <div class="relative">
    <div class="absolute left-[-62px] top-1/3 z-10 hidden md:block">
      <div :class="$style.template">
        <RevIllustration
          alt=""
          :class="[$style.animateBounceVertical, $style.templateArea]"
          :height="62"
          src="/img/product/out-of-stock/animated-arrow.svg"
          :width="80"
        />
      </div>
    </div>

    <div class="mb-4 flex w-full justify-end md:hidden md:justify-start">
      <RevTag
        :label="i18n(translations.recommendedProductTag)"
        variant="success"
      />
    </div>
    <div class="flex size-full flex-row items-center md:flex-col">
      <div class="flex justify-center">
        <RevIllustration
          :alt="i18n(translations.recommendedProductTag)"
          class="max-h-full w-[5.5rem] min-w-[5.5rem] md:block md:h-auto md:w-[29.125rem]"
          :height="976"
          loading="eager"
          sizes="(min-width: 768px) 466px, 88px"
          :src="recommendation.image"
          :width="976"
        />
      </div>
      <div
        class="mb-16 hidden w-full justify-end md:flex md:h-[78px] md:justify-start lg:h-[57px]"
      >
        <RevTag
          class="mt-auto"
          :label="i18n(translations.recommendedProductTag)"
          variant="success"
        />
      </div>
      <div
        class="ml-16 flex w-full flex-col items-center justify-between md:ml-0"
      >
        <div class="flex w-full flex-col md:flex-row">
          <div class="flex w-full flex-col items-start">
            <h2
              class="body-1-bold line-clamp-2 overflow-hidden text-ellipsis md:heading-1"
            >
              {{ recommendation.title }}
            </h2>
          </div>
          <div
            class="mt-8 flex shrink-0 flex-col justify-end md:mt-0 md:items-end"
          >
            <div class="body-2 block md:body-2-bold">
              {{ i18n(translations.recommendedProductPriceFrom) }}
            </div>
            <div class="flex w-full flex-row md:flex-row-reverse">
              <div class="body-2-bold mr-8 md:heading-2 md:ml-8 md:mr-0">
                {{ i18n.price(recommendation.price) }}
              </div>

              <RevTooltip
                v-if="recommendation.priceNew"
                class="z-10 flex items-end"
                content-class-names="mx-12"
                :modifiers="[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 2],
                    },
                  },
                ]"
                position="bottom"
              >
                <template #trigger="{ hide, show }">
                  <button
                    class="body-2 cursor-pointer line-through md:body-1"
                    @click="show"
                    @focusin="show"
                    @focusout="hide"
                    @mouseenter="show"
                    @mouseleave="hide"
                  >
                    {{ i18n.price(recommendation.priceNew) }}
                  </button>
                </template>
                {{ i18n(translations.recommendedProductPriceStriked) }}
              </RevTooltip>
            </div>
          </div>
        </div>

        <RatingInfo class="mt-4 hidden w-full md:flex" :rating="rating" />
      </div>
    </div>

    <div class="my-32 w-full">
      <RevButton
        class=""
        full-width="responsive"
        :to="recommendation.productPageLink"
        variant="primary"
        @click="onClickCTA"
      >
        {{ i18n(translations.cta) }}
      </RevButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'
import { RevTag } from '@ds/components/Tag'
import { RevTooltip } from '@ds/components/Tooltip'

import RatingInfo from '~/scopes/reviews/reviews-display/components/Rating/Rating.vue'

import translations from './AlternativeProduct.translations'

const props = defineProps<{
  recommendation: Product
}>()

const i18n = useI18n()
const { trackClick } = useTracking()

const rating = computed(() => {
  if (isEmpty(props.recommendation.reviewRating)) {
    return {
      count: 0,
      averageRate: 0,
    }
  }

  return {
    count: props.recommendation.reviewRating.count,
    averageRate: props.recommendation.reviewRating.average,
  }
})

function onClickCTA() {
  trackClick({
    name: 'see_alternative',
    zone: 'out_of_stock',
  })
}
</script>

<style module>
/* TODO: Move animation to DS */
.animateBounceVertical {
  animation: verticalBounce 1s infinite;
}

@media only screen and (min-width: 768px) {
  .template {
    display: grid;
    grid-template-areas: 'arrow' 'arrow-space';
  }

  .templateArea {
    grid-area: arrow;
  }
}

@keyframes verticalBounce {
  0%,
  100% {
    transform: translateX(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
</style>

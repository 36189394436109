<template>
  <div>
    <div class="flex flex-wrap items-baseline gap-x-4">
      <span
        class="heading-2"
        data-qa="productpage-product-price"
        data-test="productpage-product-price"
      >
        {{ i18n.price(price) }}
      </span>
      <PriceLabel
        :price="offer.price"
        :price-due-today
        :swap-offer
        :swap-status
      />
    </div>

    <div class="flex flex-wrap items-center gap-x-8">
      <OriginalPrice
        :is-discount-reassurance-displayed="
          offer.reassurance?.discount?.isDisplayed
        "
        :price-when-new="product.priceWhenNew"
      />

      <YouSave
        :category-id="product.tracking.categoryId"
        :is-discount-reassurance-displayed="
          offer.reassurance?.discount?.isDisplayed
        "
        :mobile-plan="mobilePlan"
        :price="offer.price"
        :price-when-new="product.priceWhenNew"
        :swap-offer
        :swap-status
      />
    </div>

    <VatIncluded class="mt-4" />
  </div>
</template>

<script lang="ts" setup>
import { type Price } from '@backmarket/http-api'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'

import OriginalPrice from './components/OriginalPrice/OriginalPrice.vue'
import PriceLabel from './components/PriceLabel/PriceLabel.vue'
import VatIncluded from './components/VatIncluded/VatIncluded.vue'
import YouSave from './components/YouSave/YouSave.vue'

withDefaults(
  defineProps<{
    offer: GetBestOffersResponse[number]
    price: Price
    priceDueToday?: Price
    product: GetProductResponse
    mobilePlan?: MobilePlanOffer
    swapStatus?: string
    swapOffer?: Estimation
  }>(),
  {
    mobilePlan: undefined,
    swapStatus: undefined,
    swapOffer: undefined,
    priceDueToday: undefined,
  },
)

const i18n = useI18n()
</script>

<template>
  <div v-if="label" class="flex flex-wrap gap-x-4">
    <span class="body-2-bold whitespace-nowrap">
      {{ label }}
    </span>
    <span
      v-if="shouldShowDueToday && priceDueToday"
      class="text-static-default-low body-2"
    >
      {{ i18n(translations.dueToday, { price: i18n.price(priceDueToday) }) }}
    </span>
  </div>
</template>

<script lang="ts" setup>
/**
 * For now this component only displays "before trade-in" mention, but in a very near future it'll also
 * display "after trade-in" with due today mention, or "with mobile plan", etc.
 */
import { computed } from 'vue'

import { type Price } from '@backmarket/http-api'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'
import { SWAP_SUCCESS } from '~/scopes/buyback/swap/components/SwapBlock/SwapBlock.constants'
import { useUrlParams } from '~/scopes/product/composables/useUrlParams'

import {
  COUNTRIES_WITH_TRADE_IN_PRICE_LABEL,
  COUNTRIES_WITH_TRADE_IN_PRICE_LABEL_DISCOUNTED_SWAP,
} from '../../Price.constants'

import translations from './PriceLabel.translations'

const props = defineProps<{
  swapStatus?: string
  swapOffer?: Estimation
  priceDueToday?: Price
}>()

const i18n = useI18n()
const { mobilePlan } = useUrlParams()
const experiments = useExperiments()

const {
  market: { countryCode },
} = useMarketplace()

const isSwapActive = computed(() => {
  return props.swapStatus === SWAP_SUCCESS && props.swapOffer?.hasOffer
})

const shouldShowDueToday = computed(() => {
  return (
    experiments['experiment.ppDiscountedSwap'] === 'withDiscountedSwap' &&
    isSwapActive.value &&
    props.priceDueToday
  )
})

const label = computed(() => {
  if (experiments['experiment.ppDiscountedSwap'] === 'withDiscountedSwap') {
    if (mobilePlan.value && isSwapActive.value) {
      return i18n(translations.withTradeInAndMobilePlan)
    }

    if (mobilePlan.value) {
      return i18n(translations.withMobilePlan)
    }

    if (isSwapActive.value) {
      return i18n(translations.withTradeIn)
    }

    if (
      COUNTRIES_WITH_TRADE_IN_PRICE_LABEL_DISCOUNTED_SWAP.includes(countryCode)
    ) {
      return i18n(translations.beforeTradeIn)
    }
  }

  if (mobilePlan.value) {
    return i18n(translations.withMobilePlan)
  }

  if (COUNTRIES_WITH_TRADE_IN_PRICE_LABEL.includes(countryCode)) {
    return i18n(translations.beforeTradeIn)
  }

  return null
})
</script>

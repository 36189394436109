<template>
  <div>
    <RecommendationBundle
      v-if="currentGrade && currentGrade.id"
      class="mb-72 overflow-hidden"
      :main-product="{
        title: product.title,
        description: product.subTitleElements.join(' - '),
        image: product.images?.[0]?.url,
        productPageLink: product.link,
        grade: currentGrade.backboxGrade,
        price: currentGrade.price,
        priceNew: product.priceWhenNew,
        listingId: `${currentGrade.id}`,
        brand: product.brand,
        category: product.tracking.categoryName,
        id: product.id,
        model: product.model,
        specifications: {},
        listingPublicId: currentGrade.publicId,
        mobilePlanOfferId: mobilePlan,
        partnerPromoCodes: product.includedServiceOffers.partnerPromoCodes,
      }"
      :title="i18n(translations.bundleTitle)"
      @add-to-cart="onBundleAddToCart"
    />

    <RecommendationCarousel
      class="mb-72"
      :options="{
        withCrossedPrice: true,
        withStartingFrom: true,
        withGrade: true,
        withAddToCart: false,
      }"
      :recommendation-query="{
        category: 'cousinsUp',
        scope: 'product',
        scopeId: productId,
        limit: 4,
      }"
      :title="i18n(translations.moreTitle)"
      :trackingData="{
        list: 'product page cousins 1',
      }"
      @error="onRecommendationsError"
      @loaded="onRecommendationsLoaded"
    />

    <RecommendationCarousel
      class="mb-72"
      :options="{
        withCrossedPrice: true,
        withStartingFrom: true,
        withGrade: true,
        withAddToCart: false,
      }"
      :recommendation-query="{
        category: 'cousinsDown',
        scope: 'product',
        scopeId: productId,
        limit: 4,
      }"
      :title="i18n(translations.crossTitle)"
      :trackingData="{
        list: 'product page cousins 2',
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import { watch } from 'vue'

import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import RecommendationCarousel from '@backmarket/nuxt-layer-recommendation/RecommendationCarousel.vue'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import RecommendationBundle from '~/scopes/recommendation/components/RecommendationBundle.vue'

import { useUrlParams } from '../../composables/useUrlParams'

import translations from './Recommendations.translations'

const { currentGrade, product } = defineProps<{
  product: GetProductResponse
  currentGrade: GetBestOffersResponse[number] | null | undefined
}>()

const emit = defineEmits<{
  'bundle-add-to-cart': [Array<string>]
  'recommendations-loaded': [Array<Product>]
  'recommendations-error': []
}>()

const i18n = useI18n()
const { productId, mobilePlan } = useUrlParams()
const logger = useLogger()

function onBundleAddToCart(listings: Array<string>) {
  emit('bundle-add-to-cart', listings)
}

function onRecommendationsLoaded(products: Array<Product>) {
  emit('recommendations-loaded', products)
}

function onRecommendationsError() {
  emit('recommendations-error')
}

watch(
  () => currentGrade,
  (newCurrentGrade) => {
    if (newCurrentGrade && !newCurrentGrade.id) {
      logger.info('[TEMP] current grade without id', {
        newCurrentGrade,
        product,
      })
    }
  },
)
</script>

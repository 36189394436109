<template>
  <div class="bg-surface-default-mid rounded-lg p-24">
    <div class="mb-16 flex">
      <div class="relative shrink-0">
        <RevIllustration
          alt=""
          class="mr-16 w-40 flex-initial"
          :height="40"
          src="/img/product/seller/SellerAvatar.svg"
          :width="40"
        />
      </div>

      <SellerBlock :grade="grade" />
    </div>
    <div class="border-static-default-low flex border-t pt-20">
      <p class="body-2">
        {{ i18n(translations.paymentMethodAccepted) }}
      </p>

      <PaymentIcons
        class="ml-24 content-center justify-end"
        :methods="paymentMethods"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { PaymentIcons } from '@backmarket/nuxt-module-payment/methods'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './Seller.translations'
import SellerBlock from './components/SellerBlock/SellerBlock.vue'

const props = defineProps<{
  grade: GetBestOffersResponse[number]
}>()

const i18n = useI18n()

// undefined is needed to have country fallback for payment methods
const paymentMethods = computed(() => {
  return props.grade.paymentMethods.length > 0
    ? props.grade.paymentMethods
    : undefined
})
</script>

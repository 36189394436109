<template>
  <RevPitchBlock
    v-if="content.display"
    id="ecoBlocks"
    ref="root"
    :cards="cards"
    class="overflow-x-hidden"
    :subtitle="content.description"
    :title="content.title ?? ''"
  >
    <RevLink @click="handleModalOpen">
      {{ i18n(translations.modalLinkText) }}
    </RevLink>
    <EcoBlockModal />
  </RevPitchBlock>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref, watch } from 'vue'

import { type GetEcoBlockResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/eco-block'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'
import { RevPitchBlock } from '@ds/components/PitchBlock'
import { useIntersectionObserver } from '@vueuse/core'

import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './EcoBlock.translations'
import EcoBlockModal from './components/EcoBlockModal/EcoBlockModal.vue'

const props = defineProps<{
  content: GetEcoBlockResponse
}>()

const root = ref<HTMLElement | null>()
const hasTriggeredImpression = ref(false)

const i18n = useI18n()
const { trackProductBlockImpression } = useTracking()

const cards = computed(() =>
  (props.content.figures || []).map(({ title, description, type }) => ({
    title,
    description,
    image: {
      alt: '',
      height: 150,
      src: `/img/product/eco-block/${type}.svg`,
      width: 150,
    },
  })),
)

function handleModalOpen() {
  openModal(MODAL_NAMES.RENEWED_IMPACT)
}

const { stop } = useIntersectionObserver(root, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    trackProductBlockImpression({
      block_name: 'eco_block',
    })
    hasTriggeredImpression.value = true
  }
})

watch(hasTriggeredImpression, () => {
  if (hasTriggeredImpression.value) {
    stop()
  }
})

onUnmounted(() => {
  stop()
})
</script>

import { type Price } from '@backmarket/http-api'

/**
 * Apply the offer to the price, making sure it stays >= 0
 */
export const getPriceWithTradeIn = (
  price: Price,
  offerPrice: number,
): string => {
  const withSubsidy = parseFloat(price.amount) - offerPrice

  if (withSubsidy < 0) {
    return '0'
  }

  return withSubsidy.toFixed(2).toString()
}

import { type Ref, computed, ref, watch } from 'vue'

import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import {
  type GetPickersResponse,
  type Picker,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isBrowser } from '@backmarket/utils/env/isBrowser'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { isEqual } from '@backmarket/utils/object/isEqual'

import { MAX_REVIEWS_DISPLAYED_IN_LIST } from '~/scopes/reviews/reviews-display/constants'

import { ProductCategory } from '../constants'
import { getFilteredItemsFromVariation } from '../utils/getFilteredItemsFromVariation'

import { useUrlParams } from './useUrlParams'

function getMobilePlanOffers(variation: string, picker?: Picker): string {
  if (!picker) return 'no_offer'

  const items = picker.items.filter(
    (item) => item.trackingValue !== 'smartphone_only',
  )

  return (
    // We run an A/B/C test which is filtering the items (see https://backmarket.atlassian.net/wiki/spaces/2C/pages/3960307861/2024-07-31+-+Trade-in+A+B+test+and+Bouygues+new+offers#%3ABouygues%3A-Bouygues-new-offer)
    getFilteredItemsFromVariation(items, variation)
      .map((item) => item.trackingValue)
      .join('-') ?? 'no_offer'
  )
}

export function useProductTracking(
  product: Ref<GetProductResponse | null>,
  offer: Ref<GetBestOffersResponse[number] | null | undefined>,
  offers: Ref<GetBestOffersResponse | null | undefined>,
  rating: Ref<Rating | null>,
  pickers: Ref<GetPickersResponse | null>,
  isOffersResponsePending: Ref<boolean | null>,
  isPickersResponsePending: Ref<boolean | null>,
) {
  const { offerType, grade } = useUrlParams()
  const { trackProductPage } = useTracking()
  const experiments = useExperiments()
  const previousEvent = ref<unknown>(null)

  const productEndpointData = computed(() => ({
    dealType: 'normal',
    brand: product.value?.brand ?? '',
    category: product.value?.tracking.categoryName ?? '',
    color: product.value?.tracking.color ?? '',
    image: product.value?.images?.[0]?.url ?? '',
    isSwapEnabled: product.value?.isSwapEligible ?? false,
    model: product.value?.model ?? '',
    name: product.value?.rawTitle ?? '',
    uuid: product.value?.id ?? '',
    webUrl: product.value?.link?.href ?? '',
  }))

  const partnerPromoCodes = computed(() => {
    if (
      !product.value ||
      isEmpty(product.value.includedServiceOffers.partnerPromoCodes)
    ) {
      return 'no_offer'
    }

    return product.value.includedServiceOffers.partnerPromoCodes.length > 0
      ? product.value.includedServiceOffers.partnerPromoCodes
          .map((item) => item.partnerName)
          .join('-')
      : 'no_offer'
  })

  const offerEndpointData = computed(() => ({
    currency: offer.value?.price?.currency ?? '',
    hasExpressShipping: !!offer.value?.reassurance?.shipping?.express,
    listingId: offer.value?.id ?? 0,
    merchantId: String(offer.value?.merchant?.id) ?? '',
    sellerOrigin:
      offer.value?.merchant?.countryCode ||
      offer.value?.merchant?.country ||
      '',
    price: offer.value?.price?.amount ?? '',
    shippingDelay: offer.value?.reassurance?.shipping?.free?.delayInHours ?? 0,
    stock: offer.value?.reassurance?.stock?.stockRawValue ?? 0,
    variant: offer.value?.backboxGrade?.value ?? 0,
  }))

  const pickersEndpointData = computed(() => {
    const productHasGoodDealGrade =
      pickers.value?.pickers
        .find((group) => group.id === 'grades')
        ?.items.findIndex((picker) => picker.goodDeal) !== -1

    const gradeSelectedPicker = pickers.value?.pickers
      .find((group) => group.id === 'grades')
      ?.items.find((picker) => picker.selected)

    const mobilePlanPicker = pickers.value?.pickers.find(
      (group) => group.id === 'mobile_plan',
    )

    const mobilePlanOffers = getMobilePlanOffers(
      experiments['experiment.ppBouyguesOffersFilter'],
      mobilePlanPicker,
    )

    const mobilePlanOfferSelected =
      pickers.value?.pickers
        .find((group) => group.id === 'mobile_plan')
        ?.items.filter((item) => item.trackingValue !== 'smartphone_only')
        .find((picker) => picker.selected)?.trackingValue ?? ''

    const hasPremium =
      pickers.value?.pickers.find((group) => group.id === 'grades')?.items
        .length === 4

    const hasPremiumInStock =
      pickers.value?.pickers
        .find((group) => group.id === 'grades')
        ?.items.find((item) => item.grade?.value === 9)?.available ?? false

    return {
      productPriceTagSelected: gradeSelectedPicker?.goodDeal || false,
      productPriceTagType: productHasGoodDealGrade
        ? 'good_deal_grade'
        : 'no_tag',
      mobilePlanOffers,
      mobilePlanOfferSelected,
      hasPremium,
      hasPremiumInStock,
    }
  })

  // To move to pickersEndpointData when M&M will be released
  const newBattery = computed(() => {
    const batteryPicker = pickers.value?.pickers.find(
      (group) => group.id === 'battery',
    )

    const hasNewBattery =
      product.value?.tracking.categoryId === ProductCategory.SMARTPHONE
        ? Boolean(batteryPicker)
        : ['disabled', 'available'].includes(
            offer.value?.newBattery?.status ?? '',
          )

    const hasNewBatteryInStock =
      product.value?.tracking.categoryId === ProductCategory.SMARTPHONE
        ? Boolean(batteryPicker?.items[1].available)
        : offer.value?.newBattery?.status === 'available'

    return {
      hasNewBattery,
      hasNewBatteryInStock,
    }
  })

  const rateEndpointData = computed(() => ({
    averageRate: rating.value?.averageRate ?? 0,
    hasReviews: (rating.value?.count ?? 0) > 0,
    numberTotalReviews: rating.value?.count ?? 0,
    numberReviewsDisplayed: Math.min(
      rating.value?.count ?? 0,
      MAX_REVIEWS_DISPLAYED_IN_LIST,
    ),
  }))

  const urlData = computed(() => ({
    mobileDeeplink: `backmarket://product/${
      product.value?.id
    }?specialOfferType=${offerType?.value ?? '0'}`,
    specialOfferType: offerType?.value ?? '0',
  }))

  // This is not yet set in front-apps, but in pastrami we get some information for the tracking
  // set in the session storage. It's used to know where the user comes from (reco, search, bundle...)
  const sessionStorageData = computed(() => {
    try {
      if (!product.value?.id || !isBrowser() || !window.sessionStorage) {
        return { list: '' }
      }

      const item = sessionStorage.getItem(product.value?.id)
      if (!isEmpty(item)) {
        return { list: JSON.parse(item as string).source }
      }
    } catch {
      // fails silently
    }

    return { list: '' }
  })

  const productTracking = computed(() => ({
    ...productEndpointData.value,
    ...offerEndpointData.value,
    ...pickersEndpointData.value,
    ...rateEndpointData.value,
    ...urlData.value,
    ...sessionStorageData.value,
    ...newBattery.value,
    partnerPromoCodes: partnerPromoCodes.value,
  }))

  const listingsTracking = computed(() =>
    (offers.value ?? []).map(({ id, backboxGrade = {}, price = {} }) => ({
      id,
      variant: backboxGrade.value,
      price: price.amount,
    })),
  )

  // Makes sure we're sending the real data once both calls are finished.
  const isRequestPending = computed(() => {
    return isOffersResponsePending.value || isPickersResponsePending.value
  })

  watch(
    [grade, offerType, isRequestPending],
    () => {
      if (
        !isRequestPending.value &&
        !isEqual(productTracking.value, previousEvent.value)
      ) {
        trackProductPage({
          product: productTracking.value,
          listings: listingsTracking.value,
        })

        previousEvent.value = productTracking.value
      }
    },
    { immediate: true },
  )

  return {
    product: productTracking,
    listings: listingsTracking,
  }
}

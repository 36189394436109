<template>
  <div
    class="bg-static-info-low rounded-sm mt-12 flex items-center gap-8 px-12 py-8"
  >
    <span class="heading-2">+</span>

    <RevIllustration
      :alt="plan.partner?.name"
      :height="20"
      src="/img/bouygues/bouyguesLogoSquare.svg"
      :width="20"
    />

    <p v-if="prices" class="body-2">
      {{
        i18n(translations.plan, {
          price: i18n.price(prices[0].price),
          duration: prices[0].monthsDuration ?? 0,
        })
      }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './MobilePlan.translations'

const i18n = useI18n()

const props = defineProps<{
  plan: MobilePlanOffer
}>()

const prices = computed(() => {
  return props.plan.priceOptions.find(
    ({ option }) => option === 'KEEP_PHONE_NUMBER',
  )?.prices
})
</script>

<template>
  <div>
    <AsyncModal
      :fetch="fetchWarrantyServices"
      :name="MODAL_NAMES.PERKS"
      :title="i18n(translations.title)"
    >
      <template #content>
        <div>
          <h3 v-if="warranty.title" class="heading-3">
            {{ warranty.title }}
          </h3>

          <div v-if="shouldShowServices" class="mt-16">
            <ContentRow
              v-for="service in services"
              :key="service.title"
              :hyperlink="service.hyperlink"
              :labels="service.texts"
              :title="service.title"
            >
              <component :is="service.icon"></component>
            </ContentRow>
          </div>
        </div>
        <WarrantyExclusion />
      </template>
    </AsyncModal>
  </div>
</template>

<script lang="ts" setup>
import { useRouteParams } from '#imports'
import { computed, ref } from 'vue'

import {
  type GetWarrantyServicesResponse,
  getWarrantyServices,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/warranty-services'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { IconCalendar } from '@ds/icons/IconCalendar'
import { IconCreditCard } from '@ds/icons/IconCreditCard'
import { IconExports } from '@ds/icons/IconExports'
import { IconHeart } from '@ds/icons/IconHeart'
import { IconTools } from '@ds/icons/IconTools'

import { MODAL_NAMES } from '~/scopes/product/constants'

import AsyncModal from '../../../AsyncModal/AsyncModal.vue'
import ContentRow from '../../../ContentRow/ContentRow.vue'
import WarrantyExclusion from '../../../WarrantyExclusion/WarrantyExclusion.vue'

import translations from './PerksModal.translations'

const ICONS: Record<string, unknown> = {
  warranty: IconTools,
  return: IconCalendar,
  shipping: IconExports,
  customerCare: IconHeart,
  instalmentPayment: IconCreditCard,
}

const props = defineProps<{
  listingId: number
}>()

const warranty = ref()
const i18n = useI18n()
const { uuid: productId } = useRouteParams()

const shouldShowServices = computed(() => {
  return !isEmpty(warranty.value.includedServices)
})

const services = computed(() => {
  return (
    shouldShowServices.value &&
    warranty.value.includedServices.map(
      ({
        type,
        title,
        texts,
        hyperlink,
      }: GetWarrantyServicesResponse['includedServices'][number]) => {
        return {
          icon: ICONS[type],
          title,
          texts,
          hyperlink,
        }
      },
    )
  )
})

async function fetchWarrantyServices() {
  const { data } = await useHttpFetch(getWarrantyServices, {
    pathParams: { id: productId, listingId: String(props.listingId) },
  })

  warranty.value = data.value
}
</script>

<template>
  <div
    class="flex w-full flex-col gap-48 md:flex-row"
    :class="{ 'justify-center': !shouldDisplayRightColumn }"
  >
    <ExpectedProduct
      :breadcrumb="breadcrumb"
      class="w-full md:w-1/2"
      :is-loading="shouldDisplaySkeleton"
      :product="product"
      :rating="rating"
      :recommendation="alternative"
    />

    <div v-if="shouldDisplayRightColumn" class="w-full md:w-1/2">
      <AlternativeSkeleton v-if="shouldDisplaySkeleton" class="size-full" />
      <AlternativeProduct v-if="alternative" :recommendation="alternative" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type GetBreadcrumbResponseV2 } from '@backmarket/http-api/src/api-specs-navigation-experience/product/breadcrumb'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import AlternativeProduct from './components/AlternativeProduct/AlternativeProduct.vue'
import AlternativeSkeleton from './components/AlternativeSkeleton/AlternativeSkeleton.vue'
import ExpectedProduct from './components/ExpectedProduct/ExpectedProduct.vue'

const props = withDefaults(
  defineProps<{
    breadcrumb: GetBreadcrumbResponseV2['breadcrumb'] | null
    product: GetProductResponse
    rating?: Rating
    alternative: Product | null
    isAlternativeLoading: boolean
  }>(),
  {
    rating: () => ({ averageRate: 0, count: 0 }),
  },
)

const shouldDisplaySkeleton = computed(() => {
  return props.isAlternativeLoading === true
})

const shouldDisplayAlternative = computed(() => {
  return !isEmpty(props.alternative)
})

const shouldDisplayRightColumn = computed(() => {
  return shouldDisplaySkeleton.value || shouldDisplayAlternative.value
})
</script>

export default {
  advertisement: {
    id: 'product_page_trade_in_advertisement',
    defaultMessage: 'Get this for even less with Trade-in',
  },
  enabled: {
    id: 'product_page_trade_in_advertisement_active',
    defaultMessage: '{price} trade-in value applied',
  },
}

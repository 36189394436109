import { type Locale } from '@backmarket/http-api'
import {
  type GetBreadcrumbResponse,
  type GetBreadcrumbResponseV2,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/breadcrumb'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'

export const transformBreadcrumbResponse = (
  breadcrumb: GetBreadcrumbResponse,
  locale: Locale,
): GetBreadcrumbResponseV2 => {
  const crumbs = breadcrumb
    .slice(0, -1)
    .map(({ title, link }) => ({
      label: title,
      // Home link on badoom doesn't have a href, so we need to manage it manually
      url: link.name === 'home' ? `/${toBcp47Locale(locale)}` : link.href,
    }))
    .filter(({ url }) => !!url)

  return {
    // TS does not want to see that i'm filtering out the undefined urls, so hard casting here.
    breadcrumb: crumbs as unknown as GetBreadcrumbResponseV2['breadcrumb'],
  }
}

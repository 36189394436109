import { type LinkInternal } from '../../api-models'
import { createHttpEndpoint } from '../../utils'

/**
 * Given a product id, returns all the data needed to render the breadcrumb
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/product-page-api/definition#tag/breadcrumb/operation/getBreadcrumb}
 */
export const getBreadcrumb = createHttpEndpoint<GetBreadcrumbResponse>({
  method: 'GET',
  operationId: 'getBreadcrumb',
  path: '/bm/product/:productId/breadcrumb',
})

/**
 * @see {@link https://devportal.backmarket.io/catalog/default/api/search-service-api/definition#/public/breadcrumb}
 */
export const getBreadcrumbV2 = createHttpEndpoint<GetBreadcrumbResponseV2>({
  method: 'GET',
  operationId: 'getBreadcrumb',
  path: '/bm/landing_page/product/:productId/breadcrumb',
})

export type GetBreadcrumbResponse = Array<{
  title: string
  slug: string
  link: LinkInternal
}>

export type GetBreadcrumbResponseV2 = {
  breadcrumb: Array<{
    label: string
    url: string
  }>
}

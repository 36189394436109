import { type Grade } from '../../api-models'
import { createHttpEndpoint } from '../../utils'

/**
 * Given a product id, returns all the text and image descriptions associated to all the grades
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/product-page-api/definition#tag/gradeDescriptions/operation/getGradeDescriptions}
 */
export const getGradeDescriptions =
  createHttpEndpoint<GetGradeDescriptionsResponse>({
    method: 'GET',
    operationId: 'getGradeDescriptions',
    path: '/bm/product/:productId/grade_descriptions',
  })

export type GetGradeDescriptionsResponse = Array<{
  backboxGrade: Grade
  images: Array<{
    alt: string
    url: string
  }>
  paragraphs: Array<{
    title: string
    tag: string
    texts: Array<string>
    tooltip?: Array<string>
    link?: {
      text: string
      url: string
    }
  }>
}>

<template>
  <div v-if="productResponse">
    <SkinnyBanner page="product" />
    <Breadcrumb
      v-if="breadcrumbResponse"
      :breads="breadcrumbResponse.breadcrumb"
      class="bg-surface-default-low"
      :class="{ 'hidden md:block': areImmersiveStoriesEnabled }"
      :title="productResponse.rawTitle"
    />

    <ImmersiveStories
      v-if="!isOutOfStock && areImmersiveStoriesEnabled"
      class="md:hidden"
      :images="productResponse.images"
      :tracking-category="productResponse.tracking.categoryName"
      :tracking-model="productResponse.model"
    >
      <template #breadcrumb>
        <Breadcrumb
          v-if="breadcrumbResponse"
          :breads="breadcrumbResponse.breadcrumb"
          class="md:hidden"
          :title="productResponse.rawTitle"
        />
      </template>
      <template #title>
        <RevContainer>
          <TitleMobile
            v-if="price"
            class="pt-8 md:hidden"
            :current-grade="currentGrade"
            :price="price"
            :product="productResponse"
            :rating="ratesResponse"
            :show-eco-block="ecoBlockContent.display"
            :swap-offer
            :swap-status="swapStatus"
            :tracking="productTracking"
          />
        </RevContainer>
      </template>
    </ImmersiveStories>

    <RevContainer class="bg-surface-default-low md:hidden">
      <TitleMobileAtc
        v-if="!isOutOfStock && areImmersiveStoriesEnabled && price"
        :current-grade="currentGrade"
        :price="price"
        :product="productResponse"
        :rating="ratesResponse"
        :selected-mobile-plan="selectedMobilePlan"
        :show-eco-block="ecoBlockContent.display"
        :swap-offer
        :swap-status="swapStatus"
        :tracking="productTracking"
      />
    </RevContainer>

    <RevContainer
      class="bg-surface-default-low pb-24 md:pb-56"
      :class="{ 'pb-18 md:pb-48': isCustomizationFunnelEnabled }"
    >
      <OutOfStock
        v-if="isOutOfStock"
        :alternative="outOfStockAlternative"
        :breadcrumb="breadcrumbResponse?.breadcrumb ?? []"
        :is-alternative-loading="outOfStockAlternativeLoading"
        :product="productResponse"
        :ratings="ratesResponse"
      />

      <div
        v-if="!isOutOfStock"
        class="flex flex-col flex-wrap md:flex-row md:flex-nowrap"
        :class="[
          {
            'items-center': isCustomizationFunnelEnabled,
            'justify-start gap-x-72': areImmersiveStoriesEnabled,
          },
        ]"
      >
        <div
          class="relative"
          :class="{
            'w-full max-w-full md:w-1/3 lg:w-1/2': !areImmersiveStoriesEnabled,
            '': areImmersiveStoriesEnabled,
          }"
        >
          <div
            class="top-[7rem]"
            :class="{
              'md:sticky md:right-1/2 md:mr-56': !areImmersiveStoriesEnabled,
            }"
          >
            <Gallery
              v-if="!areImmersiveStoriesEnabled"
              :images="productResponse.images"
              :tracking-product-model="productResponse.model"
              tracking-zone="pp_content_carousel"
            />

            <ImmersiveStories
              v-if="areImmersiveStoriesEnabled"
              class="hidden md:block"
              :images="productResponse.images"
              :tracking-category="productResponse.tracking.categoryName"
              :tracking-model="productResponse.model"
            />
          </div>
        </div>

        <div class="w-full max-w-full md:w-2/3 lg:w-1/2 lg:flex-1">
          <TitleDesktop
            v-if="price"
            class="hidden md:block"
            :current-grade="currentGrade"
            :price="price"
            :product="productResponse"
            :rating="ratesResponse"
            :selected-mobile-plan="selectedMobilePlan"
            :show-eco-block="ecoBlockContent.display"
            :swap-offer
            :swap-status="swapStatus"
            :tracking="productTracking"
          />

          <TitleMobile
            v-if="!areImmersiveStoriesEnabled && price"
            class="md:hidden"
            :current-grade="currentGrade"
            :price="price"
            :product="productResponse"
            :rating="ratesResponse"
            :show-eco-block="ecoBlockContent.display"
            :swap-status="swapStatus"
            :tracking="productTracking"
          />
          <TitleMobileAtc
            v-if="!areImmersiveStoriesEnabled && price"
            class="md:hidden"
            :current-grade="currentGrade"
            :price="price"
            :product="productResponse"
            :rating="ratesResponse"
            :selected-mobile-plan="selectedMobilePlan"
            :show-eco-block="ecoBlockContent.display"
            :swap-offer
            :swap-status="swapStatus"
            :tracking="productTracking"
          />

          <AdvertisedPayment :current-grade="currentGrade" />
          <AdvertisementChips
            :product="productResponse"
            :swap-offer="swapOffer"
            :swap-status="swapStatus"
          />

          <ReassuranceItems
            v-if="productResponse && currentGrade"
            :carrier="productResponse.carrier"
            :shipping="currentGrade.reassurance.shipping"
            :show-verified-refurbished="productResponse.showVerifiedRefurbished"
            :warranty-delay="currentGrade.reassurance.defaultWarrantyDelay"
          />

          <Pickers
            v-if="!isCustomizationFunnelEnabled"
            :category-id="productResponse.tracking.categoryId"
            :new-battery="currentGrade?.newBattery"
            :pickers="pickersResponse?.pickers ?? []"
            :swap-offer
            :swap-status
          />

          <CopyLevy
            v-if="currentGrade"
            class="my-56"
            :copy-levy="currentGrade.copyLevy"
          />

          <Details
            v-if="!isCustomizationFunnelEnabled"
            :current-grade="currentGrade"
            has-swap
            :product="productResponse"
            :product-category="productCategory"
            :swap-listings="swapListings"
            :swap-offer="swapOffer"
            :swap-status="swapStatus"
            :technical-specifications="technicalSpecificationsResponse"
          />
        </div>
      </div>
    </RevContainer>

    <RevContainer
      v-if="isCustomizationFunnelEnabled && currentGrade && price"
      class="bg-surface-default-low"
    >
      <CustomizationFunnelWrapper
        :current-grade="currentGrade"
        :is-pickers-response-pending="isPickersResponsePending"
        :new-battery="currentGrade?.newBattery"
        :pickers-response="pickersResponse"
        :price="price"
        :price-without-subsidies
        :product="productResponse"
        :product-category="productCategory"
        :product-tracking="productTracking"
        :selected-mobile-plan="selectedMobilePlan"
        :swap-listings="swapListings"
        :swap-offer="swapOffer"
        :swap-status="swapStatus"
        :technical-specifications="technicalSpecificationsResponse"
        @hide="handleHideTopBar"
        @show="handleShowTopBar"
      />
    </RevContainer>

    <RevContainer v-if="!errorReco" class="mt-56" data-id="reco-section">
      <Recommendations
        :current-grade="currentGrade"
        :product="productResponse"
        @bundle-add-to-cart="onBundleAddToCart"
        @recommendations-error="onRecommendationsError"
        @recommendations-loaded="onRecommendationsLoaded"
      />
    </RevContainer>

    <RevContainer v-if="ratesResponse?.count" class="mb-56">
      <ReviewsBlock
        id="reviews"
        :highlighted-review="highlightedReview"
        :highlighted-review-pending="isFetchHighlightedReviewPending"
        :link="reviewsPageLink"
        :product-title="productResponse.title"
        :rating="ratesResponse"
        :reviews="reviews.list"
        :reviews-pending="reviews.isPending"
        :tracking-info="productTracking.product.value"
      />
    </RevContainer>

    <div class="bg-surface-default-hi">
      <RevContainer>
        <PopularSearches
          v-if="tagsResponse"
          class="mb-32 md:mb-72"
          :tags="tagsResponse"
        />
      </RevContainer>

      <EcoBlock :content="ecoBlockContent" data-id="eco-block" />

      <RevContainer class="pt-32 md:pt-72">
        <SeoContent
          v-if="technicalSpecificationsResponse"
          class="mb-72"
          data-id="seo-technical-specs"
          :description="productResponse.description ?? ''"
          :technical-specifications="technicalSpecificationsResponse"
          :title="productResponse.title"
        />

        <AboutUs />
        <TrustList class="my-32 md:my-72" data-qa="product-reassurance" />
        <TrustCarousel class="my-32 md:my-56" />
      </RevContainer>
    </div>

    <ClientOnly>
      <SwapModal
        v-if="swapStatus !== SWAP_DISABLED"
        :datalayer-category="productCategory"
        :initialPayload="swapStore.initialPayload"
        :modal-name="BUYBACK_MODAL_NAMES.SWAP_MIX_AND_MATCH"
        :price="swapOffer?.price || ''"
        :product-title="productResponse.rawTitle"
        skip-intro-step
        :swap-listings="swapListings"
      />

      <SwapModal
        v-if="swapStatus !== SWAP_DISABLED"
        :datalayer-category="productCategory"
        :modal-name="BUYBACK_MODAL_NAMES.SWAP_ATC"
        :price="swapOffer?.price || ''"
        :product-title="productResponse.rawTitle"
        :redirect-on-close-target="CHECKOUT.CART"
        :swap-listings="swapListings"
        @continue="handleContinue"
      />

      <TradeInModal v-if="showTradeInModal" />

      <VisibleByVerizonDrawer
        v-if="
          hasVisibleByVerizon(
            productResponse.includedServiceOffers.partnerPromoCodes,
          )
        "
      />

      <AddToCartModal
        v-if="currentGrade"
        :additional-listings="additionalListings"
        :listing-id="currentGrade.id"
        :title="productResponse.rawTitle"
        @close="onAddToCartModalClose"
      />

      <StickyBarTop
        v-if="
          !isOutOfStock &&
          productResponse &&
          currentGrade &&
          price &&
          !isCustomizationFunnelEnabled
        "
        :current-grade="currentGrade"
        :price="price"
        :product="productResponse"
        :selected-mobile-plan="selectedMobilePlan"
        :swap-offer
        :swap-status="swapStatus"
        :title="productResponse.rawTitle"
        :tracking="productTracking"
      />

      <StickyBarBottom
        v-if="!isOutOfStock && productResponse && currentGrade && price"
        :offer="currentGrade"
        :price="price"
        :product="productResponse"
        :selected-mobile-plan="selectedMobilePlan"
        :swap-offer
        :swap-status="swapStatus"
        :tracking="productTracking"
      />
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRuntimeConfig } from '#imports'
import { computed, onMounted, provide, readonly, ref } from 'vue'

import { getEcoBlockDefaultValue } from '@backmarket/http-api/src/api-specs-navigation-experience/product/eco-block'
import { logDesync } from '@backmarket/nuxt-layer-recommendation/helpers.ts'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevContainer } from '@ds/components/Container'
import { openModal } from '@ds/components/ModalBase'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import SkinnyBanner from '~/scopes/branding/components/SkinnyBanner/SkinnyBanner.vue'
import { MODAL_NAMES as BUYBACK_MODAL_NAMES } from '~/scopes/buyback/constants'
import { SWAP_DISABLED } from '~/scopes/buyback/swap/components/SwapBlock/constants'
import SwapModal from '~/scopes/buyback/swap/components/SwapModal/SwapModal.vue'
import { useSwapEstimations } from '~/scopes/buyback/swap/composables/useSwapEstimations'
import { useSwapStore } from '~/scopes/buyback/swap/stores/swap'
import { CHECKOUT } from '~/scopes/checkout/routes-names'
import TrustList from '~/scopes/reassurance/components/TrustList/TrustList.vue'
import ReviewsBlock from '~/scopes/reviews/reviews-display/components/ReviewsBlock/ReviewsBlock.vue'
import { useHighlightedReview } from '~/scopes/reviews/reviews-display/composables/useHighlightedReview'
import { ROUTES as REVIEWS_ROUTES } from '~/scopes/reviews/route-names'

import AboutUs from '../components/AboutUs/AboutUs.vue'
import AddToCartModal from '../components/AddToCartModal/AddToCartModal.vue'
import AdvertisedPayment from '../components/AdvertisedPayment/AdvertisedPayment.vue'
import AdvertisementChips from '../components/AdvertisementChips/AdvertisementChips.vue'
import Breadcrumb from '../components/Breadcrumb/Breadcrumb.vue'
import CopyLevy from '../components/CopyLevy/CopyLevy.vue'
import VisibleByVerizonDrawer from '../components/CustomizationFunnel/components/VisibleByVerizonDrawer/VisibleByVerizonDrawer.vue'
import CustomizationFunnelWrapper from '../components/CustomizationFunnelWrapper/CustomizationFunnelWrapper.vue'
import Details from '../components/Details/Details.vue'
import EcoBlock from '../components/EcoBlock/EcoBlock.vue'
import Gallery from '../components/Gallery/Gallery.vue'
import ImmersiveStories from '../components/ImmersiveStories/ImmersiveStories.vue'
import OutOfStock from '../components/OutOfStock/OutOfStock.vue'
import Pickers from '../components/Pickers/Pickers.vue'
import PopularSearches from '../components/PopularSearches/PopularSearches.vue'
import ReassuranceItems from '../components/ReassuranceItems/ReassuranceItems.vue'
import Recommendations from '../components/Recommendations/Recommendations.vue'
import SeoContent from '../components/SeoContent/SeoContent.vue'
import StickyBarBottom from '../components/StickyBarBottom/StickyBarBottom.vue'
import StickyBarTop from '../components/StickyBarTop/StickyBarTop.vue'
import TitleDesktop from '../components/Title/TitleDesktop.vue'
import TitleMobile from '../components/Title/TitleMobile.vue'
import TitleMobileAtc from '../components/Title/TitleMobileAtc.vue'
import TradeInModal from '../components/TradeIn/components/TradeInModal/TradeInModal.vue'
import TrustCarousel from '../components/TrustCarousel/TrustCarousel.vue'
import { useAddGradeInUrl } from '../composables/useAddGradeInUrl'
import { useGradeFallback } from '../composables/useGradeFallback'
import { useInvalidGradeFallback } from '../composables/useInvalidGradeFallback'
import { useProductHead } from '../composables/useProductHead'
import { useProductPrice } from '../composables/useProductPrice'
import { useProductRematch } from '../composables/useProductRematch'
import { useProductRequests } from '../composables/useProductRequests'
import { useProductTracking } from '../composables/useProductTracking'
import { useRedirectionToQueryParamsURL } from '../composables/useRedirectionToQueryParamsURL'
import { useUrlParams } from '../composables/useUrlParams'
import { MODAL_NAMES, ProductCategory } from '../constants'
import { PRODUCT_INJECTION_KEY } from '../symbols'
import { hasVisibleByVerizon } from '../utils/hasVisibleByVerizon'

const route = useRoute()
const marketPlace = useMarketplace()
const experiments = useExperiments()
const {
  FF_PRODUCT_PAGE_TRADE_IN_CHIP_JP = '',
  FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_REVIEWS = '',
} = useRuntimeConfig().public

const { grade: urlGrade, offerType, productId, mobilePlan } = useUrlParams()
const resolveLocalizedRoute = useRouteLocationWithLocale()
const additionalListings = ref<Array<string>>([])
const outOfStockAlternativeLoading = ref(true)
const outOfStockAlternative = ref<Product | null>(null)
const errorReco = ref(false)

const {
  productResponse,
  bestOffersResponse,
  breadcrumbResponse,
  technicalSpecificationsResponse,
  tagsResponse,
  ecoBlockResponse,
  pickersResponse,
  isBestOffersPending,
  isPickersResponsePending,
  ratesResponse,
  reviewsResponse,
  isReviewsResponsePending,
  isOutOfStock,
  currentGrade,
} = await useProductRequests(productId, urlGrade, offerType, mobilePlan)

const { highlightedReview, isFetchHighlightedReviewPending } =
  useHighlightedReview()

// Provide the product response to make it available in other components
provide(PRODUCT_INJECTION_KEY, readonly(productResponse))

const { swapStatus, swapListings, swapOffer } = useSwapEstimations({
  isSwapEligible: productResponse.value?.isSwapEligible || false,
  grade: currentGrade,
  bestOffersResponse,
})

const swapStore = useSwapStore()

const showTradeInModal = computed(() => {
  return (
    swapStatus.value !== SWAP_DISABLED ||
    FF_PRODUCT_PAGE_TRADE_IN_CHIP_JP.includes(marketPlace.market.countryCode)
  )
})

const reviews = computed(() => ({
  isPending: isReviewsResponsePending.value,
  list: reviewsResponse.value ? reviewsResponse.value.results : [],
}))

const reviewsPageLink = computed(() => {
  if (
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_REVIEWS.includes(
      marketPlace.market.countryCode,
    )
  ) {
    return resolveLocalizedRoute({
      name: REVIEWS_ROUTES.PAGES.PRODUCT,
      params: route.params,
    })
  }

  return resolveLocalizedRoute({
    name: REVIEWS_ROUTES.PAGES.LEGACY_PRODUCT,
    params: { uuid: route.params.uuid },
    query: { productName: productResponse.value?.title },
  })
})

const productCategory = computed(() => {
  return (
    (productResponse.value !== null &&
      productResponse.value.tracking &&
      productResponse.value.tracking.categoryName) ||
    ''
  )
})

const ecoBlockContent = computed(() => {
  return ecoBlockResponse.value ?? getEcoBlockDefaultValue
})

const isCustomizationFunnelEnabled = computed(() => {
  return (
    !isOutOfStock.value &&
    (experiments['experiment.ppMixAndMatchEverywhere'] ===
      'everythingEverywhereAllAtOnce' ||
      productResponse.value?.tracking.categoryId === ProductCategory.SMARTPHONE)
  )
})

const areImmersiveStoriesEnabled = computed(() => {
  return (
    (experiments['experiment.ppImmersiveStories'] === 'withImmersiveStories' ||
      route.query?.['experiment.ppImmersiveStories'] ===
        'withImmersiveStories') &&
    productResponse.value?.tracking.categoryId === ProductCategory.SMARTPHONE
  )
})

const selectedMobilePlan = computed(() => {
  return (
    pickersResponse.value?.pickers
      ?.find(({ id }) => id === 'mobile_plan')
      ?.items?.find(({ selected }) => !!selected)?.extraData ?? undefined
  )
})

useProductRematch(productResponse)
useInvalidGradeFallback()
useGradeFallback(
  currentGrade,
  isPickersResponsePending,
  pickersResponse,
  bestOffersResponse,
)
useAddGradeInUrl(currentGrade)
useRedirectionToQueryParamsURL()
useProductHead(productResponse, currentGrade, ratesResponse, isOutOfStock)
const productTracking = useProductTracking(
  productResponse,
  currentGrade,
  bestOffersResponse,
  ratesResponse,
  pickersResponse,
  isBestOffersPending,
  isPickersResponsePending,
)
const { price, priceWithoutSubsidies } = useProductPrice({
  pickers: pickersResponse,
  offer: currentGrade,
  swapStatus,
  swapOffer,
  isCustomizationFunnelEnabled,
  isDiscountedSwapExperimentActive:
    experiments['experiment.ppDiscountedSwap'] === 'withDiscountedSwap',
})

function onAddToCartModalClose() {
  additionalListings.value = []
}
function onBundleAddToCart(listings: Array<string>) {
  additionalListings.value = listings
}

function onRecommendationsLoaded(products: Array<Product>) {
  outOfStockAlternative.value = products[0]
  outOfStockAlternativeLoading.value = false
}

function onRecommendationsError() {
  errorReco.value = true
  outOfStockAlternativeLoading.value = false
}

function handleContinue() {
  setTimeout(() => {
    openModal(MODAL_NAMES.ADD_TO_CART)
  })
}
function handleShowTopBar() {
  const header = document.getElementById('header')

  if (header && header !== null) {
    header.style.visibility = 'hidden'
  }
}
function handleHideTopBar() {
  const header = document.getElementById('header')

  if (header && header !== null) {
    header.style.visibility = 'visible'
  }
}

onMounted(() => {
  if (productResponse.value?.id) {
    logDesync({
      id: productResponse.value.id,
      isOutOfStock: isOutOfStock.value,
      price: currentGrade.value?.price?.amount,
    })
  }
})
</script>

<style>
/* On mobile, we need to add some padding to be able to see everything in the footer due to the sticky add to cart bar */
@media only screen and (max-width: 767px) {
  #main-footer {
    padding-bottom: 100px !important;
  }
}
</style>

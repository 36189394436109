import { useRoute, useRouter } from '#imports'
import { onBeforeMount } from 'vue'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { omit } from '@backmarket/utils/object/omit'

import { VALID_GRADES } from '../constants'

import { useUrlParams } from './useUrlParams'

/**
 * When the grade in the URL is not valid (i.e., #l=1), the page is broken. We should remove it to fallback on the default one instead.
 *
 * This can happen in various occasions:
 * - Wrong copy paste
 * - Errors during algolia indexation
 */
export function useInvalidGradeFallback() {
  const { grade } = useUrlParams()
  const router = useRouter()
  const route = useRoute()
  const logger = useLogger()

  // Using onBeforeMount() hook to make sure this is removed BEFORE we go through the useAddGradeInUrl composable
  // which adds the default grade in the URL if it's missing
  onBeforeMount(() => {
    if (!grade.value || VALID_GRADES.includes(grade.value)) {
      return
    }

    logger.info('[PRODUCT] Invalid grade in the URL')

    const link = {
      ...route,
      type: 'internal',
      query: omit(route.query, ['l']),
      hash: route.hash,
    }

    void router.replace(link)
  })
}

<template>
  <RevStickyBar class="hidden md:flex" position="top" :visible="showBar">
    <RevContainer class="w-full py-1">
      <div class="flex items-center">
        <RevIllustration
          v-if="productImage"
          :alt="productImage.description"
          class="mr-16"
          :height="51"
          sizes="50vw"
          :src="productImage.url"
          :width="51"
        />
        <h1 class="body-1-bold">
          {{ title }}
        </h1>
        <div class="flex grow flex-col items-end">
          <Price
            :mobile-plan="selectedMobilePlan"
            :offer="currentGrade"
            :price
            :product
            :swap-offer
            :swap-status
          />
        </div>
        <div class="ml-32 w-full max-w-256">
          <RevButton
            data-qa="product-page-buy-button-sticky-bar"
            full-width="always"
            variant="primary"
            @click="onAddToCart"
          >
            {{ i18n(translations.addToCart) }}
          </RevButton>
        </div>
      </div>
    </RevContainer>

    <slot name="progress-bar" />
  </RevStickyBar>
</template>

<script lang="ts" setup>
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue'

import { type Price as PriceType } from '@backmarket/http-api'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevContainer } from '@ds/components/Container'
import { RevIllustration } from '@ds/components/Illustration'
import { RevStickyBar } from '@ds/components/StickyBar'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'

import { useAddToCart } from '../../composables/useAddToCart'
import { useProductTracking } from '../../composables/useProductTracking'
import Price from '../Price/Price.vue'

import { POSITION_Y_STICKYBAR_DISPLAY } from './StickyBarTop.constants'
import translations from './StickyBarTop.translations'

const props = defineProps<{
  currentGrade: GetBestOffersResponse[number]
  price: PriceType
  product: GetProductResponse
  swapStatus?: string
  swapOffer?: Estimation
  selectedMobilePlan?: MobilePlanOffer
  isCustomizationFunnelEnabled?: boolean
  title: string
  tracking: ReturnType<typeof useProductTracking>
}>()

const emit = defineEmits<{
  (event: 'show'): void
  (event: 'hide'): void
}>()

const i18n = useI18n()
const { addToCart } = useAddToCart()
const showBar = ref(false)
const lastYPosition = ref(0)

watch(showBar, () => {
  if (showBar.value) {
    emit('show')
  } else {
    emit('hide')
  }
})

const productImage = computed(() => {
  return props.product.images[0] ?? null
})

function onAddToCart() {
  addToCart({
    listingId: props.currentGrade.id,
    listingPublicId: props.currentGrade.publicId,
    tracking: {
      ...props.tracking.product.value,
      list: 'header',
    },
    swapStatus: props.swapStatus || '',
    partnerPromoCodes: props.product.includedServiceOffers.partnerPromoCodes,
  })
}

function updateTopBarDisplay() {
  // Since the Title component is displayed one time for mobile and one time for desktop.
  // So the ATC button is appearing twice in the dom.
  const desktopAddToCartCta = document.querySelectorAll(
    '[data-id="product-page-buy-button-desktop"]',
  )?.[0]
  const desktopAddToCartCtaBottomScroll =
    desktopAddToCartCta?.getBoundingClientRect().bottom

  // To have the correct behavior we need to target the first component of the bottom of the page.
  // Reco are not mandatory so if the reco block is failing, we don't have our limit anymore.
  // Fallback would be reviews but we can have products without reviews.
  // Fallback would be the eco block but we have it only for smartphones.
  // Fallback would be SEO tech specs has it's on every page.
  const recoSection = document.querySelector('[data-id="reco-section"]')
  const reviewsSection = document.querySelector('#reviews')
  const ecoBlockSection = document.querySelector('[data-id="eco-block"]')
  const seoTechSpecSection = document.querySelector(
    '[data-id="seo-technical-specs"]',
  )
  const bottomPageSection =
    recoSection || reviewsSection || ecoBlockSection || seoTechSpecSection
  const bottomPageSectionTopScroll =
    bottomPageSection?.getBoundingClientRect().top

  // Mix&Match special rules:
  // Emits show and hide are specific to mix&match
  // hide and show top bar when we are in the funnel or after

  // Show the Sticky bar when ATC is no more visible
  // Show the Sticky bar when scrolling down
  // In mix&match show the Sticky bar when scrolling up passing the reco which represent to end of the funnel.
  if (
    desktopAddToCartCtaBottomScroll &&
    desktopAddToCartCtaBottomScroll <= POSITION_Y_STICKYBAR_DISPLAY &&
    (lastYPosition.value < window.scrollY ||
      (props.isCustomizationFunnelEnabled &&
        bottomPageSectionTopScroll &&
        bottomPageSectionTopScroll > 0))
  ) {
    showBar.value = true
  }

  // Hide the sticky bar when scrolling up
  if (
    desktopAddToCartCtaBottomScroll > 0 ||
    (bottomPageSectionTopScroll &&
      bottomPageSectionTopScroll <= 0 &&
      lastYPosition.value > window.scrollY)
  ) {
    showBar.value = false
  }

  lastYPosition.value = window.scrollY
}

function handleScroll() {
  if (window.matchMedia('(min-width: 768px)').matches) {
    window.requestAnimationFrame(() => {
      updateTopBarDisplay()
    })
  }
}

// The component generally gets unmounted when changing product, but when we play with the pickers
// we want to keep the bar displayed. Here we make sure to have the right display value when mounting the
// component to avoid repeating the appearance animation of RevStickyBar
onBeforeMount(() => {
  updateTopBarDisplay()
})

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  emit('hide')
  window.removeEventListener('scroll', handleScroll)
})
</script>

import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

export default {
  plan: {
    id: 'product_page_customization_result_mobile_plan',
    defaultMessage: `{price}/mois de forfait mobile {duration, plural,
      =0 {}
      =1 {le 1er mois}
      =12 {la 1ère année}
      other {les # premiers mois}
    }`,
  },
} as Record<string, I18nDefinition>

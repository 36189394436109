<template>
  <div>
    <p class="body-1 mb-4 overflow-hidden">
      {{ i18n(translations.title) }}

      <RevLink
        v-if="isValidMerchantLink"
        data-qa="seller-info"
        :to="grade.merchant.link"
        @click="onSellerClick"
      >
        {{ grade.merchant.company }}
      </RevLink>
    </p>
    <p class="text-static-default-low body-2">
      {{
        i18n(translations.subtitle, {
          location: grade.merchant.country,
          since: grade.merchant.sellerSinceYear,
        })
      }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevLink } from '@ds/components/Link'

import translations from '../../Seller.translations'

const props = defineProps<{
  grade: GetBestOffersResponse[number]
}>()

const i18n = useI18n()
const { trackClick } = useTracking()

const isValidMerchantLink = computed(() => {
  const { link } = props.grade.merchant

  return link && link.params.uuid && link.params.merchantName
})

function onSellerClick() {
  trackClick({
    zone: 'recap',
    name: 'seller',
    value:
      props.grade.merchant.countryCode || props.grade.merchant.country || '',
  })
}
</script>

<template>
  <div>
    <div class="body-2">
      <FormattedMessage
        v-if="shouldDisplayFreeShippingWithDates"
        :definition="translations.freeShipping"
      >
        <template #dates>
          {{
            i18n(translations.freeShippingDates, {
              earliest: freeEarliestArrivalDate,
              latest: freeLatestArrivalDate,
            })
          }}
        </template>
      </FormattedMessage>

      <span v-if="shouldDisplayFreeShippingWithoutDates">
        {{ i18n(translations.freeShippingWithoutDates) }}
      </span>
    </div>

    <div v-if="shouldDisplayExpressShipping" class="caption">
      {{
        i18n(translations.expressShipping, {
          amount: expressShippingPriceLabel,
          earliest: expressEarliestArrivalDate,
          latest: expressLatestArrivalDate,
        })
      }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import translations from '../../ItemShipping.translations'

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  timeZone: 'UTC',
}

const props = defineProps<{
  shipping: GetBestOffersResponse[number]['reassurance']['shipping']
}>()

const i18n = useI18n()

const shouldDisplayFreeShippingWithoutDates = computed(() => {
  return isEmpty(props.shipping?.free)
})

const shouldDisplayFreeShippingWithDates = computed(() => {
  return !isEmpty(props.shipping?.free)
})

const shouldDisplayExpressShipping = computed(() => {
  if (props.shipping?.express) {
    return Number(props.shipping.express.price.amount) > 0
  }

  return false
})

const freeEarliestArrivalDate = computed(() => {
  return props.shipping?.free
    ? i18n.date(new Date(props.shipping.free.earliestArrivalDate), DATE_FORMAT)
    : null
})

const freeLatestArrivalDate = computed(() => {
  return props.shipping?.free
    ? i18n.date(new Date(props.shipping.free.latestArrivalDate), DATE_FORMAT)
    : null
})

const expressEarliestArrivalDate = computed(() => {
  return props.shipping?.express
    ? i18n.date(
        new Date(props.shipping.express.earliestArrivalDate),
        DATE_FORMAT,
      )
    : null
})

const expressLatestArrivalDate = computed(() => {
  return props.shipping?.express
    ? i18n.date(new Date(props.shipping.express.latestArrivalDate), DATE_FORMAT)
    : null
})

const expressShippingPriceLabel = computed(() => {
  return props.shipping?.express
    ? i18n.price(props.shipping.express.price)
    : null
})
</script>

<template>
  <RevStickyBar class="block md:hidden" :visible="showBar">
    <div class="flex items-center">
      <div class="grow">
        <Price
          :mobile-plan="selectedMobilePlan"
          :offer
          :price
          :product
          :swap-offer
          :swap-status
        />
      </div>
      <RevButton
        class="ml-8 grow"
        data-qa="product-page-buy-button-mobile"
        variant="primary"
        @click="onAddToCart"
      >
        {{ i18n(translations.addToCart) }}
      </RevButton>
    </div>
  </RevStickyBar>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'

import { type Price as PriceType } from '@backmarket/http-api'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevStickyBar } from '@ds/components/StickyBar'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'

import { useAddToCart } from '../../composables/useAddToCart'
import { useProductTracking } from '../../composables/useProductTracking'
import Price from '../Price/Price.vue'

import translations from './StickyBarBottom.translations'

const i18n = useI18n()

const props = withDefaults(
  defineProps<{
    offer: GetBestOffersResponse[number]
    price: PriceType
    product: GetProductResponse
    selectedMobilePlan?: MobilePlanOffer
    swapStatus?: string
    swapOffer?: Estimation
    tracking: ReturnType<typeof useProductTracking>
    // This prop is used for unit tests to make sure the bar is here.
    showBar?: boolean
  }>(),
  {
    selectedMobilePlan: undefined,
    swapStatus: undefined,
    swapOffer: undefined,
    showBar: false,
  },
)

const showBar = ref(props.showBar)
const { addToCart } = useAddToCart()

function onAddToCart() {
  addToCart({
    listingId: props.offer.id,
    listingPublicId: props.offer.publicId,
    tracking: {
      ...props.tracking.product.value,
      list: 'product_page',
    },
    swapStatus: props.swapStatus || '',
    partnerPromoCodes: props.product.includedServiceOffers.partnerPromoCodes,
  })
}

function updateDisplay() {
  const cta = document.querySelector(
    '[data-id="product-page-buy-button-mobile"]',
  )
  const ctaPosition = cta?.getBoundingClientRect().top ?? 0
  showBar.value = ctaPosition <= 60
}

function handleScroll() {
  if (window.matchMedia('(max-width: 767px)').matches) {
    window.requestAnimationFrame(() => {
      updateDisplay()
    })
  }
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <div>
    <RevModal
      :name="MODAL_NAMES.TECH_SPECS"
      :title="i18n(translations.title)"
      variant="panel"
    >
      <template #body>
        <div class="pb-56">
          <h3 v-if="technicalSpecifications.title" class="heading-2 mx-auto">
            {{ technicalSpecifications.title }}
          </h3>

          <div v-if="showWarnings" class="mt-32">
            <RevInfoBlock
              v-for="warning in warnings"
              :key="warning.title"
              :icon="IconWarning"
              :title="warning.title"
              variant="warning"
            >
              <template #default>
                <p v-for="text in warning.texts" :key="text" class="body-2">
                  {{ text }}
                </p>

                <RevLink v-if="warning.hyperlink" :to="warning.hyperlink.link">
                  {{ warning.hyperlink.text }}
                </RevLink>
              </template>
            </RevInfoBlock>
          </div>

          <div v-if="showReassurancesBlocks">
            <div
              v-for="reassurance in reassurances"
              :key="reassurance.title"
              class="mt-32"
            >
              <p class="body-1-bold">
                {{ reassurance.title }}
              </p>

              <p v-for="text in reassurance.texts" :key="text" class="body-2">
                {{ text }}
              </p>
            </div>
          </div>

          <div v-if="showSpecifications" class="mt-32" data-test="specs">
            <div class="mb-4 mt-8">
              <RevInputText
                id="tech-spec-search"
                v-model="valueFilter"
                autocomplete="off"
                autocorrect="off"
                :icon="IconSearch"
                :label="i18n(translations.search)"
                name="tech-spec-search"
                type="search"
              />
            </div>
            <RevList class="mt-16" :has-external-borders="false">
              <RevListItemStatic
                v-for="(spec, index) in specifications"
                :key="`${spec.display}-${index}`"
              >
                <template #label>
                  <p class="body-1 mr-24">
                    {{ spec.display }}
                  </p>
                </template>
                <template #suffix>
                  <span
                    v-for="value in spec.values"
                    :key="value.label"
                    class="body-2 whitespace-nowrap"
                  >
                    {{ value.label }}
                  </span>
                </template>
              </RevListItemStatic>
            </RevList>
          </div>

          <div v-if="showAdditionalInfo" class="mt-24">
            <p v-for="info in additionalInfo" :key="info" class="body-2">
              {{ info }}
            </p>
          </div>
        </div>
      </template>
    </RevModal>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { type GetTechnicalSpecificationsResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/technical-specifications'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevInputText } from '@ds/components/InputText'
import { RevLink } from '@ds/components/Link'
import { RevList } from '@ds/components/List'
import { RevListItemStatic } from '@ds/components/ListItemStatic'
import { RevModal } from '@ds/components/Modal'
import { IconSearch } from '@ds/icons/IconSearch'
import { IconWarning } from '@ds/icons/IconWarning'

import { MODAL_NAMES } from '~/scopes/product/constants'

import { searchObjects } from '../../utils/SearchObjects'

import translations from './TechSpecModal.translations'

const props = defineProps<{
  technicalSpecifications: GetTechnicalSpecificationsResponse
}>()

const i18n = useI18n()
const valueFilter = ref('')

const showReassurancesBlocks = computed(() => {
  return !isEmpty(props.technicalSpecifications.reassurances)
})
const reassurances = computed(() => {
  return props.technicalSpecifications.reassurances
})
const showSpecifications = computed(() => {
  return !isEmpty(props.technicalSpecifications.specifications)
})
const specifications = computed(() => {
  return searchObjects(
    props.technicalSpecifications.specifications,
    'display',
    valueFilter.value,
  )
})
const showAdditionalInfo = computed(() => {
  return !isEmpty(props.technicalSpecifications.additionalInfo)
})
const additionalInfo = computed(() => {
  return props.technicalSpecifications.additionalInfo
})
const showWarnings = computed(() => {
  return !isEmpty(props.technicalSpecifications.warnings)
})
const warnings = computed(() => {
  return props.technicalSpecifications.warnings
})
</script>

<template>
  <AsyncModal
    :fetch="fetchGradesDescription"
    :name="MODAL_NAMES.GRADES"
    :title="i18n(translations.conditions)"
  >
    <template #trigger="{ open }">
      <div class="body-2-bold">
        <RevLink @click="open">
          {{ i18n(translations.moreInfo) }}
        </RevLink>
      </div>
    </template>
    <template #content>
      <RevCarousel
        v-if="hasImages"
        :alternative-button-label="i18n(translations.alternativeController)"
        :alternative-next="i18n(translations.alternativeNext)"
        :alternative-previous="i18n(translations.alternativePrevious)"
        class="mb-32"
        current-index-id="grade-modal-carousel"
      >
        <RevIllustration
          v-for="image in images"
          :key="image.url"
          :alt="image.alt"
          class="rounded-xs"
          :height="313"
          :src="image.url"
          :width="366"
        />
      </RevCarousel>

      <div class="grid grid-cols-3 gap-8">
        <RevPicker
          v-for="picker in pickers"
          :key="picker.backboxGrade.name"
          :label="picker.backboxGrade.name"
          :selected="picker.selected"
          @click="updateGrade(picker)"
        />
      </div>
      <GradeDescription class="mt-32" :details="details" />
    </template>
  </AsyncModal>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import {
  type GetGradeDescriptionsResponse,
  getGradeDescriptions,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/grade-descriptions'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCarousel } from '@ds/components/Carousel'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { RevPicker } from '@ds/components/Picker'

import { MODAL_NAMES } from '~/scopes/product/constants'

import { useUrlParams } from '../../../../composables/useUrlParams'
import AsyncModal from '../../../AsyncModal/AsyncModal.vue'

import translations from './GradesModal.translations'
import GradeDescription from './components/GradeDescription/GradeDescription.vue'

// If for any reason, the grade is not in the URL, let's fallback to the lower one.
const fallbackGrade = 12
const i18n = useI18n()
const { trackClick } = useTracking()

const gradesDescriptions = ref()
const selectedGradeIndex = ref(0)
const { productId, grade: urlGrade } = useUrlParams()

const pickers = computed(() => {
  return gradesDescriptions.value.map(
    (
      { backboxGrade }: GetGradeDescriptionsResponse[number],
      index: number,
    ) => ({
      backboxGrade,
      selected: selectedGradeIndex.value === index,
    }),
  )
})

const description = computed(() => {
  return gradesDescriptions.value?.[selectedGradeIndex.value]
})

const images = computed(() => {
  return description.value?.images
})

const details = computed(() => {
  return description.value?.paragraphs ?? []
})

const hasImages = computed(() => {
  return images.value && images.value.length > 0
})

async function fetchGradesDescription() {
  const { data } = await useHttpFetch(getGradeDescriptions, {
    pathParams: { productId },
    queryParams: { include_technical_comment: 'true' },
  })

  gradesDescriptions.value = data.value
  selectedGradeIndex.value = gradesDescriptions.value.findIndex(
    (grade: GetGradeDescriptionsResponse[number]) =>
      grade.backboxGrade.value === (urlGrade.value ?? fallbackGrade),
  )
}

function updateGrade(picker: GetGradeDescriptionsResponse[number]) {
  const newSelectedGradeIndex = gradesDescriptions.value.findIndex(
    (grade: GetGradeDescriptionsResponse[number]) =>
      grade.backboxGrade.value === picker.backboxGrade.value,
  )

  if (newSelectedGradeIndex === selectedGradeIndex.value) {
    return
  }

  selectedGradeIndex.value = newSelectedGradeIndex

  trackClick({
    zone: 'grade_picker_modal',
    name: picker.backboxGrade.value,
  })
}
</script>

import type { InternalLink } from '@backmarket/http-api'

import { VALID_GRADES } from '../constants'

export const hashToQueryLink = (link: InternalLink) => {
  const l = parseInt(link.hash.l || link.query?.l, 10)

  const query = {
    ...link.query,
    l: VALID_GRADES.includes(l) ? String(l) : undefined,
    offerType: link.hash.offerType || link.query?.offerType,
    mobilePlanOfferId:
      link.hash.mobilePlanOfferId || link.query?.mobilePlanOfferId,
  }

  const hash = {
    ...link.hash,
    l: undefined,
    offerType: undefined,
    mobilePlanOfferId: undefined,
  }

  return {
    ...link,
    query,
    hash,
  }
}

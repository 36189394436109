<template>
  <div>
    <PaymentAdvertisement
      v-if="
        !isSplitPriceExperimentEuropeVersionC &&
        currentGrade &&
        !currentGrade.isDisabled
      "
      :base-price="currentGrade.price"
      icon-classes="w-[40px] flex-shrink-0 mr-8 self-center"
      :payment-methods="currentGrade?.paymentMethods"
      spot="product"
      @open="trackOpen"
    />
    <PaymentAdvertisementExperiment
      v-if="
        isSplitPriceExperimentEuropeVersionC &&
        currentGrade &&
        !currentGrade.isDisabled
      "
      :base-price="currentGrade.price"
      :payment-methods="currentGrade?.paymentMethods"
      spot="product"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { PaymentAdvertisement } from '@backmarket/nuxt-module-payment/advertising'
import PaymentAdvertisementExperiment from '@backmarket/nuxt-module-payment/advertising/components/PaymentAdvertisementExperiment/PaymentAdvertisementExperiment.vue'
import { usePaymentAdvertisement } from '@backmarket/nuxt-module-payment/advertising/composables/usePaymentAdvertisement'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import {
  EU_COUNTRIES_WITH_SPLIT_PRICE,
  EU_SPLIT_PRICE_EXPERIMENTS_CONFIG,
} from '~/scopes/product/components/AdvertisedPayment/AdvertisedPayment.constants'

withDefaults(
  defineProps<{
    currentGrade?: GetBestOffersResponse[number] | null
  }>(),
  {
    currentGrade: null,
  },
)

const experiments = useExperiments()
const { market } = useMarketplace()

const isSplitPriceExperimentEuropeVersionC = computed(() => {
  return (
    experiments['experiment.ppSplitPriceEuropeVersion'] ===
      EU_SPLIT_PRICE_EXPERIMENTS_CONFIG.VERSION_C &&
    EU_COUNTRIES_WITH_SPLIT_PRICE.includes(market.countryCode)
  )
})

const { trackClick } = useTracking()

function trackOpen(advertisement: ReturnType<typeof usePaymentAdvertisement>) {
  trackClick({
    zone: 'installment plans',
    name: advertisement.value?.isAdvertised
      ? advertisement.value?.method.networks.join(',')
      : '',
  })
}
</script>

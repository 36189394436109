<template>
  <div v-if="shouldDisplayCopyLevy">
    <FormattedMessage
      class="caption"
      :definition="translations.linkWrapper"
      tag="p"
    >
      <template #price>
        <span>{{ copyLevyPrice }}</span>
      </template>
      <template #link>
        <RevLink @click="showModal">{{ i18n(translations.link) }}</RevLink>
      </template>
    </FormattedMessage>
    <CopyLevyModal />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type MonetaryAmount } from '@backmarket/http-api'
import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '../../constants'

import translations from './CopyLevy.translations'
import CopyLevyModal from './components/Modal/CopyLevyModal.vue'

const props = withDefaults(
  defineProps<{
    copyLevy: GetBestOffersResponse[number]['copyLevy']
  }>(),
  {
    copyLevy: null,
  },
)

const i18n = useI18n()

const shouldDisplayCopyLevy = computed(() => {
  return !isEmpty(props.copyLevy)
})

const copyLevyPrice = computed(() => {
  return shouldDisplayCopyLevy.value
    ? i18n.price(props.copyLevy as MonetaryAmount)
    : null
})

function showModal() {
  openModal(MODAL_NAMES.COPY_LEVY)
}
</script>

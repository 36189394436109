import { getPriceWithTradeIn } from '~/scopes/product/utils/getPriceWithTradeIn'

import type { Step } from './types'

/**
 * Loops through the pickers - except trade-in ones - to apply the trade-in offer to the price.
 * This will never be computed backend side, as trade-in is tied to user's cart, which would completely
 * destroy the cache on our slowest endpoint (pickers, ~300ms of p95)
 */
export const applyTradeInOffer = (
  steps: Array<Step>,
  offerPrice: number,
): Array<Step> => {
  return steps.map((step) => ({
    ...step,
    options: (step?.options ?? []).map((option) => ({
      ...option,
      price:
        step?.id !== 'tradein' &&
        // Checking option.price as it can be undefined - for instance if the option is disabled
        option.price &&
        typeof option.price !== 'string'
          ? {
              amount: getPriceWithTradeIn(option.price, offerPrice),
              currency: option.price.currency,
            }
          : option.price,
    })),
  }))
}

<template>
  <ReassuranceItem v-if="shouldDisplayCarriers">
    <template #icon>
      <IconLock v-if="isSimLocked" />
      <IconLockUnlocked v-if="!isSimLocked" />
    </template>
    <template #content>
      <div v-if="isSimLocked" class="w-full">
        <button
          class="body-2 flex w-full items-center justify-between text-left"
          @click="deployCarriersCompatibilityModal"
        >
          <div class="flex w-full justify-between">
            <span>
              {{ i18n(translations.labelLock) }}
            </span>

            <div class="ml-8 flex flex-wrap">
              <div
                class="border-static-default-low rounded-xs mb-2 ml-2 flex h-24 border p-4"
              >
                <RevIllustration
                  v-if="logo"
                  :alt="carrier?.name ?? ''"
                  class="h-[24px] w-auto"
                  :height="24"
                  :src="logo"
                  :width="60"
                />
                <span v-else>{{ carrier.name }}</span>
              </div>
            </div>
          </div>
          <IconChevronRight />
        </button>

        <CarriersCompatibilityModal type="locked" />
      </div>

      <div v-if="!isSimLocked" class="w-full">
        <button
          class="flex w-full items-center justify-between text-left"
          @click="deployCarriersCompatibilityModal"
        >
          <span class="body-2">
            {{ i18n(translations.labelUnlock) }}
          </span>
          <IconChevronRight />
        </button>
        <CarriersCompatibilityModal type="unlocked" />
      </div>
    </template>
  </ReassuranceItem>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevIllustration } from '@ds/components/Illustration'
import { openModal } from '@ds/components/ModalBase'
import { IconChevronRight } from '@ds/icons/IconChevronRight'
import { IconLock } from '@ds/icons/IconLock'
import { IconLockUnlocked } from '@ds/icons/IconLockUnlocked'

import { MODAL_NAMES } from '~/scopes/product/constants'

import CarriersCompatibilityModal from '../CarriersCompatibilityModal/CarriersCompatibilityModal.vue'
import ReassuranceItem from '../ReassuranceItem/ReassuranceItem.vue'

import translations from './ItemCarrier.translations'

const carriersLogoMap: Record<string, string> = {
  Verizon: 'verizon.svg',
  'AT&T': 'att.svg',
  'T-Mobile': 'tmobile.svg',
}

const props = defineProps<{
  carrier: GetProductResponse['carrier']
}>()

const i18n = useI18n()
const { trackClick } = useTracking()

const shouldDisplayCarriers = computed(() => {
  return !isEmpty(props.carrier) && props.carrier.isDisplayed
})

const isSimLocked = computed(() => {
  return props.carrier.locked
})

const logo = computed(() => {
  return props.carrier.name && props.carrier.name in carriersLogoMap
    ? `/img/product/carriers/${carriersLogoMap[props.carrier.name]}`
    : ''
})

function deployCarriersCompatibilityModal() {
  trackClick({
    zone: 'carrier_compatibility',
    name: 'carrier_info',
  })
  openModal(MODAL_NAMES.CARRIERS_COMPATIBILITY)
}
</script>

<template>
  <RevModal
    :name="MODAL_NAMES.SIM"
    :title="i18n(translations.title)"
    variant="panel"
  >
    <template #body>
      <div class="mb-24 text-center">
        <RevIllustration
          alt=""
          class="rounded-md w-full"
          :height="240"
          src="/img/product/sim-modal.svg"
          :width="327"
        />
      </div>
      <section class="text-center">
        <h2 class="text-static-default-hi heading-1">
          {{ i18n(translations.introTitle) }}
        </h2>
        <p class="text-static-default-low body-1 pt-16">
          {{ i18n(translations.introText) }}
        </p>
      </section>

      <RevDivider class="mb-48 mt-24" />

      <section class="text-center">
        <h2 class="text-static-default-hi heading-1 pb-8">
          {{ i18n(translations.compareTitle) }}
        </h2>
        <div class="body-1-bold flex gap-16 py-12">
          <h3 class="w-1/2">
            {{ i18n(translations.compareSim) }}
          </h3>
          <h3 class="w-1/2">
            {{ i18n(translations.compareEsim) }}
          </h3>
        </div>
        <div class="text-static-default-hi body-1 flex gap-16">
          <p
            class="bg-static-default-mid rounded-md flex w-1/2 items-center justify-center px-16 py-24"
          >
            {{ i18n(translations.compareSimText) }}
          </p>
          <p
            class="bg-static-default-mid rounded-md flex w-1/2 items-center justify-center px-16 py-24"
          >
            {{ i18n(translations.compareEsimText) }}
          </p>
        </div>
        <p class="text-static-default-low caption pt-8">
          {{ i18n(translations.compareEsimNote) }}
        </p>
      </section>

      <section>
        <h2 class="text-static-default-hi heading-2 mt-24 pt-24 text-center">
          {{ i18n(translations.comboTitle) }}
        </h2>
        <RevList class="mt-16">
          <RevListItemStatic>
            <template #label>
              <span class="heading-3">
                {{ i18n(translations.comboSubtitle1) }}
              </span>
            </template>
            <template #suffix>
              <span class="heading-3">
                {{ i18n(translations.comboSubtitle2) }}
              </span>
            </template>
          </RevListItemStatic>

          <RevListItemStatic>
            <template #label>
              {{ i18n(translations.option1) }}
            </template>
            <template #suffix>
              {{ i18n(translations.option1Desc) }}
            </template>
          </RevListItemStatic>

          <RevListItemStatic>
            <template #label>
              {{ i18n(translations.option2) }}
            </template>
            <template #suffix>
              {{ i18n(translations.option2Desc) }}
            </template>
          </RevListItemStatic>

          <RevListItemStatic>
            <template #label>
              {{ i18n(translations.option3) }}
            </template>
            <template #suffix>
              {{ i18n(translations.option3Desc) }}
            </template>
          </RevListItemStatic>

          <RevListItemStatic>
            <template #label>
              {{ i18n(translations.option4) }}
            </template>
            <template #suffix>
              {{ i18n(translations.option4Desc) }}
            </template>
          </RevListItemStatic>

          <RevListItemStatic>
            <template #label>
              {{ i18n(translations.option5) }}
            </template>
            <template #suffix>
              {{ i18n(translations.option5Desc) }}
            </template>
          </RevListItemStatic>

          <RevListItemStatic>
            <template #label>
              {{ i18n(translations.option6) }}
            </template>
            <template #suffix>
              {{ i18n(translations.option6Desc) }}
            </template>
          </RevListItemStatic>

          <RevListItemStatic>
            <template #label>
              {{ i18n(translations.option7) }}
            </template>
            <template #suffix>
              {{ i18n(translations.option7Desc) }}
            </template>
          </RevListItemStatic>
        </RevList>
      </section>
      <section class="py-56">
        <RevInfoBlock
          :icon="IconInfo"
          :title="i18n(translations.infoBlockTitle)"
          variant="info"
        >
          <p>
            {{ i18n(translations.infoBlockText1) }}
          </p>
          <p class="my-16">
            {{ i18n(translations.infoBlockText2) }}
          </p>
        </RevInfoBlock>
      </section>
    </template>
  </RevModal>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevList } from '@ds/components/List'
import { RevListItemStatic } from '@ds/components/ListItemStatic'
import { RevModal } from '@ds/components/Modal'
import { IconInfo } from '@ds/icons/IconInfo'

import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './SimModal.translations'

const i18n = useI18n()
</script>

<template>
  <div class="text-static-default-hi body-2-bold mb-32">
    <div class="mb-12 flex flex-col gap-12 md:mb-8 md:flex-row md:gap-8">
      <ItemShipping
        :class="[{ 'md:w-1/2': shouldDisplayCarriers }]"
        :shipping="shipping"
      />
      <ItemCarrier :carrier="carrier" class="md:w-1/2" />
    </div>
    <div class="flex flex-col gap-12 md:flex-row md:gap-8">
      <ItemReassurance class="md:w-1/2" :warranty-delay="warrantyDelay" />
      <ItemVerifiedRefurbished
        v-if="showVerifiedRefurbished"
        class="md:w-1/2"
      />
    </div>
    <RevDivider class="mt-12 md:mt-16" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevDivider } from '@ds/components/Divider'

import ItemCarrier from './components/ItemCarrier/ItemCarrier.vue'
import ItemReassurance from './components/ItemReassurance/ItemReassurance.vue'
import ItemShipping from './components/ItemShipping/ItemShipping.vue'
import ItemVerifiedRefurbished from './components/ItemVerifiedRefurbished/ItemVerifiedRefurbished.vue'

const props = withDefaults(
  defineProps<{
    carrier: GetProductResponse['carrier']
    shipping: GetBestOffersResponse[number]['reassurance']['shipping']
    warrantyDelay: string
    showVerifiedRefurbished?: boolean
  }>(),
  {
    showVerifiedRefurbished: false,
  },
)

const shouldDisplayCarriers = computed(() => {
  return !isEmpty(props.carrier) && props.carrier.isDisplayed
})
</script>

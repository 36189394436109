<template>
  <div>
    <AsyncModal
      :fetch="fetchExcludedAccessories"
      :name="MODAL_NAMES.EXCLUDED_ACCESSORIES"
      :title="modalTitle"
    >
      <template #content>
        <div
          class="bg-static-info-max rounded-lg mb-24 flex h-160 w-full items-center justify-center"
        >
          <RevIllustration
            alt=""
            class="w-[11.25rem]"
            :height="500"
            :src="illustration"
            :width="500"
          />
        </div>

        <div v-for="row in excludedAccessories" :key="row.title" class="mb-24">
          <ContentRow :labels="row.labels" :title="row.title">
            <component :is="row.icon" aria-hidden="true"></component>
          </ContentRow>
        </div>
      </template>
    </AsyncModal>
  </div>
</template>

<script lang="ts" setup>
import { useRouteParams } from '#imports'
import { computed, ref } from 'vue'

import {
  type GetAccessoryExclusionsResponse,
  getProductAccessoryExclusions,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/accessory-exclusions'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { RevIllustration } from '@ds/components/Illustration'
import { IconChargingWire as DSIconChargingWire } from '@ds/icons/IconChargingWire'
import { IconQuality as DSIconQuality } from '@ds/icons/IconQuality'
import { IconWarning as DSIconWarning } from '@ds/icons/IconWarning'

import { MODAL_NAMES } from '~/scopes/product/constants'

import AsyncModal from '../../../AsyncModal/AsyncModal.vue'
import ContentRow from '../../../ContentRow/ContentRow.vue'

const ICONS: Record<string, unknown> = {
  IconChargingWire: DSIconChargingWire,
  IconQuality: DSIconQuality,
  IconWarning: DSIconWarning,
}

const ILLUSTRATIONS: Record<string, string> = {
  IllustrationDeviceSmartphone:
    '/img/product/accessories/IllustrationDeviceSmartphone.svg',
}

const { uuid: productId } = useRouteParams()
const requestParams = { pathParams: { productId } }
const accessories = ref()

const modalTitle = computed(() => accessories.value?.title ?? '')
const excludedAccessories = computed(() => {
  return (accessories.value?.paragraphs ?? []).map(
    ({
      icon,
      title,
      text,
    }: GetAccessoryExclusionsResponse['paragraphs'][number]) => ({
      icon: ICONS[icon],
      labels: [text],
      title,
    }),
  )
})
const illustration = computed(
  () =>
    ILLUSTRATIONS[accessories.value?.image ?? 'IllustrationDeviceSmartphone'],
)

async function fetchExcludedAccessories() {
  const { data } = await useHttpFetch(
    getProductAccessoryExclusions,
    requestParams,
  )

  accessories.value = { ...data.value }
}
</script>

import type { Component } from 'vue'

import { type Grade } from '@backmarket/http-api'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { IconBattery } from '@ds/icons/IconBattery'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'
import { IconDiamond } from '@ds/icons/IconDiamond'
import { IconOfficialFilled } from '@ds/icons/IconOfficialFilled'
import { IconScratches } from '@ds/icons/IconScratches'
import { IconSparkles } from '@ds/icons/IconSparkles'

import translations from './ConditionGuidance.translations'

type GradeGalleryPill = {
  text: I18nDefinition
  icon: Component
  trackingName: string
}

type GradeGallery = Array<{
  src: string
  alt?: I18nDefinition
  title?: I18nDefinition
  pills?: Array<GradeGalleryPill>
}>

export const MODEL_DIRECTORY_PREFIX: Record<string, string> = {
  default: 'default',
  'iPhone 12': 'iphone_12',
  'iPhone 12 mini': 'iphone_12',
  'iPhone 12 Pro': 'iphone_12_pro',
  'iPhone 12 Pro Max': 'iphone_12_pro',
  'iPhone 13': 'iphone_13',
  'iPhone 13 mini': 'iphone_13',
  'iPhone 13 Pro': 'iphone_13_pro',
  'iPhone 13 Pro Max': 'iphone_13_pro',
  'Galaxy S21 5G': 'galaxy_s21',
  'Galaxy S21 FE 5G': 'galaxy_s21',
  'Galaxy S21 Ultra 5G': 'galaxy_s21',
  'Galaxy S21+ 5G': 'galaxy_s21',
}

const pillVerifiedParts: GradeGalleryPill = {
  text: translations.pillVerifiedParts,
  icon: IconCheckInCircle,
  trackingName: 'body_verified_parts',
}

const batteryForDailyUse: GradeGalleryPill = {
  text: translations.pillBatteryForDailyUse,
  icon: IconBattery,
  trackingName: 'body_battery_daily_use',
}

const batteryForHeavyUse: GradeGalleryPill = {
  text: translations.pillBatteryForHeavyUse,
  icon: IconBattery,
  trackingName: 'body_battery_heavy_use',
}

export const GRADE_TO_GALLERY: Partial<Record<Grade['value'], GradeGallery>> = {
  9: [
    {
      src: '9_body.jpg',
      title: translations.premium1_title,
      pills: [
        {
          text: translations.premium1_pill1,
          icon: IconDiamond,
          trackingName: 'body_best_quality',
        },
        {
          text: translations.premium1_pill2,
          icon: IconDiamond,
          trackingName: 'body_no_signs_of_use',
        },
        {
          text: translations.premium1_pill3,
          icon: IconOfficialFilled,
          trackingName: 'body_brand_parts',
        },
        batteryForHeavyUse,
      ],
    },
    {
      src: '9_screen.jpg',
      title: translations.premium2_title,
      pills: [
        {
          text: translations.premium2_pill,
          icon: IconDiamond,
          trackingName: 'screen_flawless',
        },
      ],
    },
  ],
  10: [
    {
      src: '10_body.jpg',
      title: translations.excellent1_title,
      pills: [
        {
          text: translations.excellent1_pill,
          icon: IconSparkles,
          trackingName: 'body_almost_no_signs_of_use',
        },
        pillVerifiedParts,
        batteryForDailyUse,
      ],
    },
    {
      src: '10_screen.jpg',
      title: translations.excellent2_title,
      pills: [
        {
          text: translations.excellent2_pill,
          icon: IconSparkles,
          trackingName: 'screen_flawless',
        },
        pillVerifiedParts,
      ],
    },
  ],
  11: [
    {
      src: '11_body.jpg',
      title: translations.good1_title,
      pills: [
        {
          text: translations.good1_pill,
          icon: IconScratches,
          trackingName: 'body_light_signs_of_use',
        },
        pillVerifiedParts,
        batteryForDailyUse,
      ],
    },
    {
      src: '11_screen.jpg',
      title: translations.good2_title,
      pills: [
        {
          text: translations.good2_pill,
          icon: IconSparkles,
          trackingName: 'screen_flawless',
        },
        pillVerifiedParts,
      ],
    },
  ],
  12: [
    {
      src: '12_body.jpg',
      title: translations.fair1_title,
      pills: [
        {
          text: translations.fair1_pill,
          icon: IconScratches,
          trackingName: 'body_visible_signs_of_use',
        },
        pillVerifiedParts,
        batteryForDailyUse,
      ],
    },
    {
      src: '12_screen.jpg',
      title: translations.fair2_title,
      pills: [
        {
          text: translations.fair2_pill,
          icon: IconScratches,
          trackingName: 'screen_may_have_micro_scratches',
        },
        pillVerifiedParts,
      ],
    },
  ],
}

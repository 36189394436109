<template>
  <div>
    <div v-for="(detail, index) in details" :key="detail.title" class="mb-32">
      <div v-if="!shouldDisplayInfoBlock(index)">
        <p v-for="paragraph in detail.texts" :key="paragraph" class="body-1">
          {{ paragraph }}
        </p>
      </div>

      <RevInfoBlock
        v-if="shouldDisplayInfoBlock(index)"
        data-test="wrapper"
        :icon="IconQuality"
        :title="detail.title"
        variant="info"
      >
        <div
          :class="{
            'line-clamp-3 text-ellipsis': !showAllText,
          }"
        >
          <p v-for="text in detail.texts" :key="text" class="pt-12 first:pt-0">
            {{ text }}
          </p>
        </div>

        <RevLink
          v-if="!showAllText"
          class="self-start pt-8"
          underlined
          @click="handleReadMore"
        >
          {{ i18n(translations.readMore) }}
        </RevLink>
      </RevInfoBlock>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import { type GetGradeDescriptionsResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/grade-descriptions'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevLink } from '@ds/components/Link'
import { IconQuality } from '@ds/icons/IconQuality'

import translations from './GradeDescription.translations'

const props = defineProps<{
  details: GetGradeDescriptionsResponse[number]['paragraphs']
}>()

const i18n = useI18n()
const showAllText = ref(false)

function shouldDisplayInfoBlock(index: number) {
  return index === props.details.length - 1
}
function handleReadMore() {
  showAllText.value = true
}
</script>

<template>
  <AsyncModal
    :fetch="fetchRenewedImpact"
    :name="MODAL_NAMES.RENEWED_IMPACT"
    :title="modalTitle"
  >
    <template #content>
      <div
        class="bg-static-info-max rounded-lg mb-24 flex h-160 w-full items-center justify-center"
      >
        <img
          alt=""
          class="w-[11.25rem]"
          :src="toPublicPath(`/img/product/eco-block/${illustration}.svg`)"
        />
      </div>

      <ContentRow
        v-for="row in paragraphs"
        :key="row.title"
        :labels="row.labels"
        :title="row.title"
      >
        <component :is="row.icon" aria-hidden="true"></component>
      </ContentRow>

      <div v-if="shouldDisplayLinks">
        <RevButton
          v-for="link in links"
          :key="link.title"
          class="mb-8"
          full-width="always"
          :to="link.link"
          variant="secondary"
        >
          {{ link.title }}
        </RevButton>
      </div>
    </template>
  </AsyncModal>
</template>

<script lang="ts" setup>
import { toPublicPath, useRouteParams } from '#imports'
import { computed, ref } from 'vue'

import {
  type GetRenewedImpactResponse,
  getRenewedImpact,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/renewed-impact'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { IconCycle } from '@ds/icons/IconCycle'
import { IconGlasses } from '@ds/icons/IconGlasses'
import { IconLeaf } from '@ds/icons/IconLeaf'
import { IconThumbsUpOutlined } from '@ds/icons/IconThumbsUpOutlined'

import { MODAL_NAMES } from '~/scopes/product/constants'

import AsyncModal from '../../../AsyncModal/AsyncModal.vue'
import ContentRow from '../../../ContentRow/ContentRow.vue'

const ICONS: Record<string, unknown> = {
  IconLeaf,
  IconCircle: IconCycle,
  IconThumbsUp: IconThumbsUpOutlined,
  IconGlasses,
}

const { uuid: productId } = useRouteParams()

const content = ref()
const modalTitle = computed(() => content.value?.title ?? '')
const links = computed(() => content.value?.links ?? [])
const shouldDisplayLinks = computed(() => !isEmpty(links.value))
const illustration = computed(() => content.value?.image ?? '')
const paragraphs = computed(
  () =>
    content.value.paragraphs.map(
      ({
        icon,
        title,
        text,
      }: GetRenewedImpactResponse['paragraphs'][number]) => ({
        icon: ICONS[icon],
        labels: [text],
        title,
      }),
    ) ?? [],
)

async function fetchRenewedImpact() {
  const { data } = await useHttpFetch(getRenewedImpact, {
    pathParams: { productId },
  })

  content.value = { ...data.value }
}
</script>

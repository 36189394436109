import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { type Prefixes as PickerPrefixes } from '../components/LargePicker/LargePicker.types'

import type { Option } from './types'

export const mapOption = (
  option: GetPickersResponse['pickers'][number]['items'][number],
  pickerPrefix: PickerPrefixes = 'circle',
): Option => {
  return {
    ...option,
    style: {
      prefix: pickerPrefix,
    },
    link: !isEmpty(option.link)
      ? { ...option.link, hash: { ...option.link.hash, scroll: 'false' } }
      : undefined,
  }
}

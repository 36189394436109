import { type Ref, computed } from 'vue'

import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'
import { SWAP_SUCCESS } from '~/scopes/buyback/swap/components/SwapBlock/constants'

export function useProductPrice({
  pickers,
  offer,
  swapStatus,
  swapOffer,
  isCustomizationFunnelEnabled,
  isDiscountedSwapExperimentActive,
}: {
  pickers: Ref<GetPickersResponse | null>
  offer: Ref<GetBestOffersResponse[number] | null | undefined>
  swapStatus: Ref<string>
  swapOffer: Ref<Estimation | undefined>
  isCustomizationFunnelEnabled: Ref<boolean>
  isDiscountedSwapExperimentActive: boolean
}) {
  /**
   * We're using a computed to keep track of the total price as we historically get it from the best_offers endpoint,
   * but for Bouygues project, we're gonna have to find it from the pickers endpoint - which will include the subsidy.
   */
  const priceWithoutSubsidies = computed(() => {
    if (isCustomizationFunnelEnabled.value && pickers.value) {
      return pickers.value.pickers[0]?.items.find(({ selected }) => !!selected)
        ?.price
    }

    return offer.value?.price
  })

  /**
   * We need to apply trade-in offer (and in the future, mobile plan subsidy) to the total price.
   */
  const price = computed(() => {
    if (!priceWithoutSubsidies.value) {
      return undefined
    }

    const p = { ...priceWithoutSubsidies.value }

    if (
      swapStatus.value === SWAP_SUCCESS &&
      swapOffer.value?.hasOffer &&
      isDiscountedSwapExperimentActive
    ) {
      const rawProductPrice = parseFloat(p.amount)
      const swapPrice = swapOffer.value.offerPriceRaw
      const computedPrice = rawProductPrice - swapPrice

      p.amount = `${computedPrice < 0 ? 0 : computedPrice}`
    }

    return p
  })

  return {
    priceWithoutSubsidies,
    price,
  }
}

import { useState } from '#imports'

import {
  addServiceToCart,
  addToCart as addToCartAPI,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import type { I18n } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { openModal } from '@ds/components/ModalBase'

import { MODAL_NAMES as SWAP_MODAL_NAMES } from '~/scopes/buyback/constants'
import {
  SWAP_DISABLED,
  SWAP_SUCCESS,
} from '~/scopes/buyback/swap/components/SwapBlock/constants'
import type { SearchQuery } from '~/scopes/search/composables/useProductsSearch'

import { MODAL_NAMES } from '../constants'

import translations from './useAddToCart.translations'
import { useUrlParams } from './useUrlParams'

type ErrorWithType = {
  type: string
}

function hasType(error: unknown): error is ErrorWithType {
  return (
    typeof error === 'object' &&
    error !== null &&
    Object.prototype.hasOwnProperty.call(error, 'type')
  )
}

function getErrorToastParams(error: unknown, i18n: I18n) {
  if (hasType(error)) {
    if (error.type === '/errors/cart/add-product-quantity') {
      return {
        title: i18n(translations.addProductQuantityErrorTitle),
        content: i18n(translations.addProductQuantityErrorDescription),
      }
    }

    if (
      error.type === '/errors/cart/add-service-to-bag/already-one-mobile-plan'
    ) {
      return {
        title: i18n(translations.alreadyOneMobilePlanErrorTitle),
        content: i18n(translations.alreadyOneMobilePlanErrorDescription),
      }
    }
  }

  return {}
}

export const useAddToCart = () => {
  const { openErrorToast } = useTheToast()
  const { trackAddToCart, trackErrors } = useTracking()
  const { mobilePlan } = useUrlParams()
  const i18n = useI18n()

  const addToCart = async ({
    listingId,
    listingPublicId,
    tracking,
    swapStatus,
    partnerPromoCodes,
  }: {
    listingId: number
    listingPublicId: string
    tracking: object
    swapStatus: string
    partnerPromoCodes: GetProductResponse['includedServiceOffers']['partnerPromoCodes']
  }) => {
    trackAddToCart({
      ...tracking,
      quantity: 1,
    })

    const querySearchInfos = useState<SearchQuery>('search-query')

    try {
      await $httpFetch(addToCartAPI, {
        body: {
          listing_id: listingId,
          searchRelatedQuery: {
            id: querySearchInfos.value?.id ?? null,
            index: querySearchInfos.value?.index ?? null,
          },
        },
      })

      if (mobilePlan.value) {
        await $httpFetch(addServiceToCart, {
          body: {
            type: 'MOBILE_PLAN',
            listingPublicId,
            offerId: mobilePlan.value,
          },
        })
      }

      const visibleByVerizonOffer = partnerPromoCodes.find(
        (partnerPromoCode) => partnerPromoCode.partnerName === 'VISIBLE',
      )

      if (visibleByVerizonOffer) {
        await $httpFetch(addServiceToCart, {
          body: {
            type: 'PARTNER_PROMO_CODE',
            listingPublicId,
            offerId: visibleByVerizonOffer.id,
            partnerName: visibleByVerizonOffer.partnerName,
          },
        })
      }

      if (swapStatus !== SWAP_SUCCESS && swapStatus !== SWAP_DISABLED) {
        openModal(SWAP_MODAL_NAMES.SWAP_ATC)
      } else {
        openModal(MODAL_NAMES.ADD_TO_CART)
      }
    } catch (error) {
      trackErrors({
        zone: 'add_to_cart',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message: (error as any)?.payload?.detail ?? 'unknown_error',
      })

      const toastParams = getErrorToastParams(error, i18n)

      openErrorToast(toastParams)
    }
  }

  return {
    addToCart,
  }
}
